
.useraction-header.elan {
    background-color: white;
    background-image: url(../images/provisioner/header\ background.svg), linear-gradient(#1b3d8d, #549cd0);

}
.useraction-header.ic {
    background-color: white;
    /* background-image: url(../images/provisioner/header\ background.svg), linear-gradient(#1b3d8d, #549cd0); */

}

.useraction-header select {
    font-size       : 11px;
    color           : #333333;
    font-family     : 'U.S.BankCircularWeb-Regular' !important;
    font-weight     : normal;
    width           : 100%;
    border          : none;
    border-bottom   : 1px solid #666666;
    margin-left     : 0;
    background-color: inherit;

}

.useraction-header .ant-select-selection-selected-value {
    font-size: 12px !important;
}

.leftusbankLogo {
    margin-left: 20%;
}

@media (width: 1024px) {
    .leftusbankLogo {
        margin-left: 75%;
    }
}

@media screen and (max-width: 850px) {
    .header select {
        margin-left: 2%;
        width      : 180%
    }
}

.header-logout {
    color    : #0C2074;
    font-size: 13px;
}

.header {
    overflow        : hidden;
    background-color: white;
    padding         : 3px 10px;
}

.header-right {
    float: right;
}

.cadre-col {
    margin-left  : -5%;
    /* margin-top: -1%; */
    margin-top   : -0.5%;
}

.cadre-col-actions {
    /*   margin-top: -1%; */
    margin-top: -0.5%;
}

.home-col {
    margin-top: 0.5%;

}

.profile-col {
    margin-top: 0.5%;
}

.user-col {
    margin-left: -5%;
}

.header-home {
    font-size: 25px;
    color    : #0C2074;
}

.header-cadre {
    color    : #0C2074;
    font-size: 73px;
    width    : 100%;
    height   : 56px;
}

.useraction-header .logo {
    color : #0C2074;
    width : 139.35px;
    height: 28.86px;

}

.iconFontsize {
    font-size: 18px;
}

/**********************style classes only for IE**********************/
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .useraction-header .logo {
        color         : #0C2074;
        width         : 139.35px;
        min-width     : 139.35px;
        height        : 28.86px;
        /* margin-left: -35%; */
        /* font-size  : 81px; */
        /*   width    :100%;
        height        : 56px; */

    }

    .creatertrip-header .logo {
        color       : #0C2074;
        width       : 139.35px;
        min-width   : 139.35px;
        height      : 28.86px;
        margin-left : -35%;
        /* font-size: 81px; */
        /*   width  :100%;
        height      : 56px; */

    }
}


@media screen and (max-width: 500px) {
    .useraction-header .logo {
        color : #0C2074;
        width : 78.35px !important;
        height: 19.86px !important;
    }

    .iconFontsize {
        font-size: 12px;
    }
}

.header-profile {
    color      : #0C2074;
    margin-left: 0%;
    font-size  : 46px;
    margin-top : -19%;
}

.header-user {
    color      : #0C2074;
    font-size  : 14px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    margin-top : 4%;
    margin-left: 2.25%;
}


/*****footer******/
#footer {
    width              : 100%;
    height             : 142.36px;
    background-image   : url(../images/Footerimage.png);
    background-repeat  : no-repeat;
    max-width          : initial;
    max-height         : initial;
    padding-top        : 0px;
    background-size    : cover;
    background-position: center;
    opacity            : 1;
}

.useraction-header>div>div {
    height         : 94px;
    align-items    : center;
    /* justify-content: center; */
    display        : flex;
    padding        : 3px
}

.useraction-header .logout-button {
    /*  border: 1px solid #0C2074; */
    color               : #0A41C5;
    /*  background-color: white;
    padding             : 5px;
    border-radius       : 4px; */
    font-size           : 15px;
    cursor              : pointer;
    font-family         : 'U.S.BankCircularWeb-Regular' !important;
}

.home_header {
    display        : flex;
    align-items    : center;
    justify-content: center
}

.home_header_row {
    display      : flex;
    align-content: stretch;
}

.headerClass .ant-select-selection {
    border       : none;
    border-radius: 0px;
    border-bottom: none !important;
}

.elan .headerlogo-card {
    margin-top: 20px;
}
.elan .headerlogo-card, .elan .header-user, .elan .logout-button, .elan .user-roles{
    color: white;
}


.elan .user-roles option{
    color: #636466
}
.header-index{
    z-index: 2;
}