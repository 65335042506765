
.aiportInput .input-bg{
  background-color: #f2f2f2;
  width: 100%; 
  border: none; 
  border-bottom: 1px solid lightgrey;
  font-size:22px;
  font-family:'U.S.BankCircularWeb-Regular' !important;
  color: #636466;
  font-weight: normal;
  margin-left: 0%;
}
.spacebtwn-checkbox{
  margin-left: 5%;
}
.ant-calendar-picker-clear {
  opacity: 0;
  z-index: 0;
  color:white;
  background: #f2f2f2;
  pointer-events: none;
  cursor: pointer;
}
#actualDates span.ant-input{
  width:100%;
}
span.ant-input {
    font-family: "U.S.BankCircularWeb-Regular";
    /* font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-radius: 0px;
   /*  padding: 4px 11px; */
    width:68%;
    height: 27px;
    font-size: 14px;
    line-height: 1.5;
    color: grey;
    background-color: #f2f2f2;
    background-image: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid lightgrey;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }
  .ant-checkbox-input, span.ant-checkbox > span.ant-checkbox-inner{
    border-color:#0A41C5;
    background-color:#f2f2f2;
  }
  .ant-checkbox-input, span.ant-checkbox-checked > span.ant-checkbox-inner{
    border-color:#0A41C5;
    background-color:#0A41C5;
  }
  .flight-itenerary-box{
    border-radius: 15px;
    padding-bottom: 1%;
    margin-top: 1%;
    background-color: #fff;
  }
  .hotel-itenerary-box{
    border-radius: 15px;
    padding-bottom: 1%;
    margin-bottom: 2%;
    margin-top: 2%;
    background-color: #fff;
    
  }
  .left-space{
   margin-left: 2%;
  }
  .left-space1{
    margin-left: 3%;
    color: black;
    margin-top: 2%;
  }
  .error-meassage{
    color:#c92323;
    font-size:14px;
    margin-top: 2%;
  }
  .spinner {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
    background: transparent;
    opacity :0.5;
    width:100%;
    height:100%;
    z-index:1000;
  }

 .pendingUpload{
  margin-top: 3%;
  font-family: 'U.S.BankCircularWeb-Regular' !important;
  color: #808080;
  font-size: 14px;
}
  .trip-itenarydetails{
    color: #636466;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 20px;
    margin-left: 8%;
    margin-top: 20%;
  }
  
  .trip-filelist-line{
   margin-top: 2%;
}
  .trip-upload{
    margin-top: 53%;
  }
  .tripAll-icon{
    margin-top: 40%;
    margin-left: 35%;
  }
  .trip-button{
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 18px;
    /* margin-top: 2%; */
    width: 100%; 
}
.progressbar-margin{
  margin-left: -3%;
}

  @media all and (max-width: 800px) {
    .spacebtwn-checkbox{
      margin-left: 5%;
    }
    .tripAll-icon{
        margin-top: 2%;
        margin-left: 2%;
     }
     .dash-button{
        margin-top: 2%;
        width: 100%; 
    }
    .trip-filelist-line{
      margin-top: 2%;
    }
    .trip-itenarydetails{
      color: #636466;
      font-family: 'U.S.BankCircularWeb-Regular' !important;
      font-size: 20px;
      margin-left: 2%;
      margin-top: 2%;
    }
    .trip-upload{
        margin-top: 7%;
        margin-left: 2%;
    }
  }
  span .ant-input:focus {
    border-color: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-right-width: 0px !important;
  }
  .itenary-icons{
    font-size: 50px;
    font-weight:bold;
  }
  @media all and (max-width: 648px) {
    .itenary-icons{
      font-size: 30px;
      font-weight:bold;
    }
  }

  .ant-input-disabled:hover{
    border-color: none;
  }
  .ant-input:hover {
    border-color: none;
}
.ant-input:focus {
  border-color: none !important;
}