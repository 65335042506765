/*********ProvisionerComponent CSS*********/
.container-header {
    box-shadow                : 0px 3px 5px 5px #f2f2f2;
    background-color          : #0a41c5;
    background-image          : url(../images/provisioner/header\ background.svg);
    color                     : white;
    padding                   : 2% 3%;
    border-bottom-right-radius: 50px;
    height                    : 202px;
    background-size           : cover;
}

/* .header-com a {
    color: white;
} */
.instant-card {
    display       : inline;
    color         : white;
    font-size     : 14px;
    font-family   : "U.S.BankCircularWeb-Regular";
    letter-spacing: 4px;
}

.header-span {
    padding-right: 5%;
    font-family   : "U.S.BankCircularWeb-Regular";
}

.header-span:hover {
    font-weight: bold;
    font-size  : 15px;
}

.popover-header-span{
    margin-top: -3%;
}


.profile-dropdown {
    margin-left: -50%;
    padding    : 10% 20%;
}

.profile-dropdown div {
    text-align   : center;
    margin-bottom: 7%;
}

.profile-dropdown span {
    color: #444;
}

.profile-dropdown b {
    color    : rgb(22, 26, 128);
    font-size: 15px;
}

.profile-dropdown .profile-icon {
    width      : 30%;
    margin-left: 33%;
    margin-top : 8%;
}

.profile-dropdown button {
    box-shadow: none;
}

.profile-dropdown button {
    color    : #0a41c5 !important;
    font-size: 15px;   
    font-weight: 600;
    width:85%;
}

.profile-dropdown button:hover, .profile-dropdown button:focus {
    border: 1px #0a41c5 solid;
}

.search-button {
    text-align: center;
}

.search-button button {
    margin-left  : 25%;
    padding      : 0 30%;
    color        : #0a41c5 !important;
    border-radius: 8px;
    height       : 50px;
}

.search-button button>span {
    font-size: 18px;
}

.search-input {
    margin                    : 0 0 2% 3.5%;
    background                : white;
    box-shadow                : none;
    border-top-left-radius    : 8px;
    border-bottom-left-radius : 8px;
    border-top-right-radius   : 0;
    border-bottom-right-radius: 0;
    min-height                : 50px;
    color                     : #333333
}

.search-input>div {
    width      : 85%;
    margin-left: 8%;
}

.search-input>div>div:hover {
    background: white;
}

.ant-input{
    color  : #333333

}

.ant-select-disabled {
    background: white !important;
}

.search-icon {
    margin-top  : 1.5%;
    padding-left: 2%;
    color       : #0a41c5;
    background  : none;
    font-size   : 23px;
    opacity     : 0.9;
    margin-left : -93%;
}

.close-icon {
    color      : #0a41c5;
    font-size  : 23px;
    background : white;
    opacity    : 0.9;
    margin-left: 90%;
}

.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    line-height: 35px !important;
}

.sort-dropdown-div {
    margin                    : 0 0 2% 0;
    background                : white;
    border-left               : 3px #0a41c5 solid;
    border-top-left-radius    : 0;
    border-bottom-left-radius : 0;
    border-top-right-radius   : 8px;
    border-bottom-right-radius: 8px;
    min-height                : 50px;
}

.sort-dropdown {
    width     : 90%;
    border    : none;
    color     : black;
    background: white !important;
    margin    : 0 2%;
}

.ant-btn-primary-disabled,
button[disabled] {
    color: black !important;
}

:root .ant-dropdown-trigger>.anticon.anticon-down,
:root .ant-dropdown-link>.anticon.anticon-down {
    font-size: 20px !important;
    color    : #0a41c5;
}

.sort-dropdown .anticon-up {
    font-size: 20px !important;
    color    : #0a41c5;
}

.sort-dropdown span {
    padding  : 0 6%;
    font-size: 15px;
}

.sort-dropdown i {
    line-height: 2.5 !important;
}

.drop-down-menu li {
    padding-left: 8%;
}

.line-text {
    margin-left: 4%;
    font-size  : 20px;
}

.search-text {
    font-size : 28px;
    color: #fff;
    margin-top: 2%;
    margin-bottom: 1%;
}

.filter {
    text-align: right;
}

.filter:hover img {
    border       : none;
    border-radius: 10%;
    background   : #6989d6;
}

.filter img {
    width       : 45%;
    margin-right: 30%;
    margin-top  : 8%;
}

.drop-down-sort li {
    padding: 5px;
}

.drop-down-sort:hover li {
    background: none !important;
}

.show-all {
    padding-bottom: 4%;
}

.show-all button {
    color    : white;
    font-size: 13px;
}

.show-all:hover button {
    border       : 1px solid white;
    border-radius: 5%;
    color        : white;
}

.ant-btn-link:active,
.ant-btn-link.active,
.ant-btn-link:hover,
.ant-btn-link:focus {
    color           : white;
    background-color: transparent;
}

.bulk-card-close-button:focus{
    outline        : dotted 2px rgb(255, 255, 255) !important;
}

.card-component {
    margin  : 6.5em 4% 4% 4%;
    position: relative;
    top     : -150px;
}

.card-border {
    margin: 1.5%;
}

.create-card-border {
    margin-left : 1.5%;
    margin-right: 1.5%;
}

.create-card:hover {
    background: rgb(22, 26, 128);
    color     : white;
}

.card-body {
    width        : 100%;
    height       : 310px;
    padding      : 0 2%;
    border-radius: 8%;
    border       : 3px solid #f5f5f5;
}

.card-body b {
    color: rgb(22, 26, 128);
}

.user-body {
    width        : 100%;
    height       : 120px;
    padding      : 0 2%;
    border-radius: 20px;
    border       : 3px solid #f5f5f5;
}

.create-cardContent {
    width        : 100%;
    height       : 348px;
    padding      : 0 2%;
    border-radius: 20px;
    border       : 3px solid #f5f5f5;
    background   : #3668DA 0% 0% no-repeat padding-box;
    color        : #ffffff;
    font-size    : 16px;
}

/* .user-body b {
    color: rgb(22, 26, 128);
} */
.user-body>div {
    padding   : 8% 0;
    text-align: center;
}

.user-card-details {
    padding   : 5%;
    color     : rgba(0, 0, 0, .65);
    text-align: center;
}

.user-card-details div {
    padding: 2% 0;
}

.user-card-details:hover {
    border    : #adadad 2px solid;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.09);
}

.user-highlight {
    border    : #adadad 2px solid;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.09);
    font-size : 16px;
    padding   : 3%;
}

.mini-card {
    padding-bottom: 10%;
}

.mini-card img {
    width         : 60%;
    padding-bottom: 8%;
}

.mini-card-details {
    text-align: center;
}

.mini-card-details div {
    padding: 2% 0;
    color  : rgba(0, 0, 0, .65);
}

.mini-card-details:hover {
    border    : #adadad 2px solid;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.09);
}

.email-address {
    width        : 90%;
    overflow     : hidden;
    display      : inline-block;
    text-overflow: ellipsis;
    white-space  : nowrap;
}

.mimi-card-blank {
    height: 30px;
}

.mimi-card-button {
    padding-left: 50% !important;
}

.mimi-card-button span {
    margin       : 2%;
    padding      : 0 6%;
    font-size    : 10px;
    border-radius: 5%;
    box-shadow   : 5px 10px 18px #adadad;
    background   : none;
}

.mimi-card-button button {
    margin-top: 5%;
    height    : 15px !important;
    min-width : 15px !important;
    background: #ffa500;
    border    : #ffa500 1px solid;
}

.Unassigned-div {
    box-shadow: none !important;
    margin    : 27% !important;
}

.card-unassign {
    width: 20%;
}

.plus-circle-o {
    font-size: 30px;
    color    : rgb(22, 26, 128);
}

.create-card:hover .plus-circle-o {
    color: white;
}

.button-create {
    color: rgb(22, 26, 128);
}

.create-card:hover b {
    color: white;
}

.left-arrow {
    padding-top: 9%;
    margin-left: -7%;
}

.left-arrow button {
    width : 50px;
    height: 50px;
    color : rgb(22, 26, 128);
}

.right-arrow {
    padding-left : 4% !important;
    padding-right: 0px !important;
    padding-top  : 9%;
}

.right-arrow button {
    width : 50px;
    height: 50px;
    color : rgb(22, 26, 128);
}

.left-arrow button:hover,
.right-arrow button:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);
    color     : rgb(22, 26, 128);
    border    : none;
}

.user-arrow-right {
    padding-top : 3%;
    padding-left: 2% !important;
}

.user-arrow-left {
    padding-top: 3%;
    margin-left: -5%;
}

.load-more {
    margin: 3% 46%;
}

.load-more button {
    color     : #0a41c5 !important;
    border    : 1px #0a41c5 solid !important;
    text-align: center;
    font-size : 12px;
}

.load-more:hover button {
    color     : white !important;
    background: rgb(22, 26, 128) !important;
    border    : none;
}

.blank-details {
    padding   : 12% 30%;
    font-size : 18px;
    text-align: center;
}

.detail-title {
    font-size: 18px;
    padding  : 2% 0;
    color    : rgb(22, 26, 128);
}

.profile-icon {
    background   : #edf4ff;
    border-radius: 12%;
    width        : 35%;
    height       : 46px;
    margin-left  : 32%;
    margin-bottom: 15%;
}

.profile-icon i {
    font-size : 30px;
    color     : rgb(22, 26, 128);
    margin-top: 11%;
}

.card-details {
    margin-left      : 8%;
    background       : #020203;
    background-size  : 100% 100%;
    background-repeat: no-repeat;
    height           : 195px;
    border-radius    : 4%;
    color            : white;
    width            : 77%;
    font-size        : 14px;
}

.card-img {
    background-image: url(../images/provisioner/ic/cardNew1.png);
}

.card-img-master {
    background-image: url(../images/provisioner/ic/cardMaster1.png);
}

.card-img.elan {
    background-image: url(../images/provisioner/elan/elanVisa_4.png);
    color: #333333;
    border: 1px solid #DBD8D8;
}

.card-img-master.elan {
    background-image: url(../images/provisioner/elan/cardMaster.png);
}

.elan .card-blur {
    background-image: none;
    background-color: #080808;
}

.elan .card-details p {
    padding-top : 26%;
    padding-left: 12%;
    font-weight : bold;

}

.elan .card-num, .elan .card-footer{
    font-size   : 12px;
    font-family : 'U.S.BankCircularWeb-Regular';
}

.send-otp-btn.elan, .elan .retry-card{
    text-transform  : uppercase;
    font-family     : 'U.S.BankCircularWeb-Light';
}

.elan .card-details .anticon-warning {
    font-size: 16px;
}

.elan .retry-card {
    font-size  : 14px;
    margin-left: 38%;   
    border-color: #333333 !important;
    color: #333333 !important;
}

.elan .retry-card>span,  
.elan .retry-card.ant-btn-background-ghost:hover{
    
    border-color: #333333 !important;
    color: #333333 !important;
}

.elan .retry-card.ant-btn-background-ghost:focus{
    border-color: #333333 !important;
    color: #333333 !important;
    outline: 2px dotted black !important; 
}

.card-blur {
    background-image: none;
    background-color: #080808;
}

.card-details p {
    padding-top : 23%;
    padding-left: 12%;
    font-weight : bold;
}

.card-details .anticon-warning {
    font-size: 16px;
}

.retry-card {
    font-size  : 14px;
    margin-left: 38%;
}

.card-head {
    padding: 3%;
}

.card-saved {
    width: 8%;
}

.card-num {
    margin-left: 5%;
    margin-top : 25%;
}

.card-footer {
    margin-top : 6%;
    margin-left: 5%;
}

.card-cvv {
    padding-left: 29%;
}

.visa {
    margin-left: 20%;
    margin-top : 3%;
}

.blur-card {
    padding   : 19% 8% 15% 8%;
    font-size : 10px;
    text-align: center;
}

.blur-card button {
    margin-top: 12%;
    font-size : 15px;
}

/* .blur-card:hover button {
    color : white !important;
    border: white 1px solid !important;
} */

.card-spin {
    margin-left  : 50%;
    margin-top   : 20%;
    width        : 30%;
    text-align   : left;
    margin-bottom: 30%;
}

.confirm-visible {
    margin-left: 64%;
}

.confirm-visible .ant-modal-content {
    width: 80%;
}

.confirm-visible .ant-modal-close {
    visibility: hidden;
}

.confirm-visible .ant-modal-header {
    font-size: 18px;
    padding  : 5%;
    border   : none;
}

.confirm-visible .ant-modal-title {
    font-weight: bold;
    color      : rgb(22, 26, 128);
}

.confirm-visible .ant-modal-title:before {
    content      : "";
    position     : absolute;
    bottom       : 78%;
    width        : 19%;
    border-bottom: 4px rgb(22, 26, 128) solid;
    border-radius: 12%;
}

.confirm-visible .ant-modal-body {
    text-align: center;
    border    : none;
}

.confirm-visible .ant-modal-body>p {
    margin: 10% 0;
}

.confirm-visible .ant-modal-footer {
    border    : none;
    text-align: center
}

.deactivate-modal .ant-modal-footer{
    border-top: 1px solid #e8e8e8 !important;
}

.deactivate-modal .ant-modal-body, .confirm-visible .ant-modal-body{
    color: #333333;
}

.modal-create-card .ant-modal-body{
    padding: 0%;
}

.custom-modal-bulk-card .ant-modal-header{
    border-radius: 15px 15px;
    border-bottom: 0px;
    padding: 0vw 0vw;
}

.req-details.disable-input{
    background-color: #f5f5f5 !important;
    color: #333333 !important;
    padding: 2% 65% 1.5% 3%;
    opacity: 0.6 !important;
}

.modal-create-card-card .ant-modal-content{
    border-radius: 15px;
}

.confirm-visible .ant-modal-footer button {
    font-size: 12px;
    border: 1px solid #666666 !important;
}

.confirm-visible .ant-modal-footer .ant-btn:focus, .reject-btn.ant-btn:focus, .cancel-btn-ant.ant-btn:focus,
.reject-btn.ant-btn:hover, .cancel-btn-ant.ant-btn:hover, .cancel-custom-btn.ant-btn:focus, .cancel-custom-btn.ant-btn:hover,
.confirm-visible .ant-modal-footer .ant-btn:hover{
    
    color     : #19398A;
    border-color: #19398A;
}

.confirm-visible .ant-modal-footer .ant-btn-primary:focus,
.confirm-visible .ant-modal-footer .ant-btn-primary:hover{
    
    color     : white;
}

.confirm-visible .anticon-warning {
    color    : #CB4E01;
    font-size: 90px;
}

.confirm-visible .anticon-check-circle {
    color        : #077207;
    font-size    : 90px;
    margin-top   : 15%;
    margin-bottom: 2%;
}

.success-box .ant-modal-footer button {
    border     : 0;
    margin-left: -2%;
}

.drawer-head {
    color: rgb(22, 26, 128);
}

.drawer-head i {
    margin-left: 60%;
}

.drawer-head svg {
    font-size: 22px;
}

.drawer-head div {
    width        : 12%;
    margin       : 1.5%;
    border-bottom: rgb(22, 26, 128) 3px solid;
}

.drawer-body {
    margin     : 12% 20%;
    text-align : center;
    color      : black;
    font-size  : 13px;
    padding-top: 20%;
}

.verification-text {
    color: rgb(22, 26, 128);
}

.drawer-body div {
    margin: 15% 0;
}

.otp-error-div div {
    margin: 12% 0 !important;
}

.otp-error-text {
    color      : #adadad !important;
    font-weight: normal;
}

.otp-error-text i {
    color    : #FFC107;
    font-size: 17px;
    padding  : 0 2%
}

.otp-input {
    display: inline;
    margin : 0 !important;
}

.otp-input input {
    margin       : 0 1% !important;
    /* XW-61 - new classes added to fix 6 input digit css for UI*/
    width        : 11%;
    border-top   : none;
    border-left  : none;
    border-right : none;
    border-radius: 0;
    border-bottom: 2px #adadad solid;
    font-size    : 20px !important;
}

.show {
    margin-left : 15% !important;
    margin-right: -20% !important;
    color       : #adadad;
    display     : relative;
    display     : inline !important;
}

.show:hover {
    color: #0a41c5 !important;
}

.otp-button {
    margin        : 5%;
    padding-bottom: 2px;
}

.otp-button span {
    font-size: 15px;
}

.resend-padding {
    margin-left: -12% !important;
}

.resend-otp-button span {
    font-size: 13px;
}

.send-otp-btn:focus{
    color: #4B72DD !important;
    border-color: #4B72DD !important ;
}

.ant-btn.send-otp-btn, .send-otp-btn:hover{
    color           : white !important;
    border-color    : white  !important;

}

.resend-otp-button {
    color     : #0a41c5;
    border    : none !important;
    box-shadow: none;
}

.resend-otp-button:hover {
    color        : #0a41c5 !important;
    border       : 1px #0a41c5 solid !important;
    border-radius: 5% !important;
}

.ant-btn.focusbutton.btn-custom:visited,
.ant-btn.focusbutton.btn-custom:hover,
.ant-btn.focusbutton.btn-custom:focus {
    color           :  #0a41c5 !important;
}

.ant-btn.focusbutton.btn-custom.ant-btn-primary:focus,
.ant-btn.focusbutton.btn-custom.ant-btn-primary:hover,
.ant-btn.focusbutton.btn-custom.ant-btn-primary:visited{
    color: white !important;
}

.language-option{
    text-align: center;
    padding-top: 2px;
    padding-bottom : 2px;
}



.resend-otp-button:visited,
.resend-otp-button:focus {
    color : rgb(22, 26, 128) !important;
    border: none !important;
}

.count-down {
    color    : #077207;
    font-size: 20px;
}

.count-down svg {
    opacity    : 0.9;
    margin-left: 120%;
}

.counter {
    padding         : 1% 2%;
    font-size       : 12px;
    /* border       : #077207 3px solid; */
    /* border-radius: 100%; */
}

.error-text {
    font-size: 12px !important;
    text-align: left !important;
    color: #C92323 !important;
}

.error-border {
    border-bottom: 2px #e8291a solid !important;
}

.create-head {
    color         : rgb(22, 26, 128);
    padding-bottom: 1%;
    font-size: 20px;
    line-height: 24px
}

.create-head i {
    float: right;
}

.create-head svg {
    font-size: 22px;
}

.create-head div {
    width        : 5%;
    margin       : 0.5% 0;
    border-bottom: rgb(22, 26, 128) 3px solid;
}

.create-head.profile-details div{
    width        : 9%;
    margin       : 0.5% 0;
    border-bottom: rgb(22, 26, 128) 5px solid;
    border-radius: 5px;
}

.profile-details .ant-avatar svg{
    fill : #666666;
}

.drop-down-create {
    margin-left  : -35%;
    border       : none;
    border-radius: 3%;
    padding      : 10% 5%;
}

.menu-head {
    font-size  : 14px;
    font-weight: bold;
    color      : rgb(22, 26, 128);
    margin     : 2% 0;
}

.menu-head span {
    padding: 5%;
}

.menu-body {
    color      : gray;
    padding-top: 5%;
}

.menu-body span {
    padding: 0 12% 0 4.5%;
}

.create-card-details-body {
    width        : 50%;
    margin-bottom: 3%;
}

.create-card-details-body div {
    margin-top: 1%;
}

.disable-input {
    background   : white !important;
    color        : #444 !important;
    border-color : #adadad !important;
    padding      : 0 65% 1.5% 3%;
    font-size    : 13px !important;
    border-bottom: 1px #adadad solid;
}

.valid-form {
    padding: 4.6% 4%;
}

.calendar {
    margin-left: -8%;
}

.create-card-details-body input {
    width        : 90%;
    border-top   : none;
    border-left  : none;
    border-right : none;
    border-radius: 0;
    border-bottom: 1px #adadad solid;
    height       : 27px;
}

.create-card-details-body textarea {
    width        : 90%;
    border-top   : none;
    border-left  : none;
    border-right : none;
    border-radius: 0;
    border-bottom: 1px #adadad solid;
}

.create-card-details-body span {
    /* color    : #444; */
    font-size: 10px;
}

.create-card-details-body .ant-calendar-picker {
    width: 90%;
}

.blue-border {
    border-bottom: 2px #0a41c5 solid !important;
}

.blue-border.date-picker{
    border-bottom : none !important;
}

.ant-calendar-picker {
    top  : 0 !important;
    width: 100%;
}

.ant-calendar-picker input {
    border: none;
}

.suggestion-text {
    display : flex;
    position: absolute;
}

.error-icon {
    color      : #e8291a;
    margin-left: -6%;
    opacity    : 0.9;
}

.limit-lable {
    margin-left: -90%;
    opacity    : 0.9;
    display    : inline;
}

.create-card-details-head {
    font-weight: bold;
    color      : #0a41c5;
}

.create-holder-details {
    margin-top: 6%;
}

.create-holder-details-body {
    width        : 50%;
    margin-bottom: 3%;
}

.create-holder-details-body div {
    margin-top: 1%;
}

.create-holder-details-body input {
    width        : 90%;
    border-top   : none;
    border-left  : none;
    border-right : none;
    border-radius: 0;
    height       : 27.5px;
}

.create-holder-details-body span {
    /* color    : #444; */
    font-size: 12px;
}

.phone-number-div {
    margin-top   : 3%;
    margin-bottom: 0;
}

.phone-number-div p {
    margin-top: -5%;
    font-size : 12px;
}

.phone-number-div select {
    font-size: 14px;
}

.phone-number-div input {
    width: 67.5%;
}

.create-holder-details-head {
    font-weight  : bold;
    margin-bottom: 5px;
    color        : #0a41c5;
}

.create-button {
    text-align: center;
}

.pull-right {
    text-align: right;
    margin-top: -27px;
}

.create-button button {
    background-color: #0a41c5;
    color           : white;
    font-size       : 14px;
    margin-top      : 8%;
}

.create-button button:hover {
    border    : none !important;
    background: rgb(22, 26, 128) !important;
    color     : white !important;
}

.create-button button:focus {
    border    : none !important;
    background: rgb(22, 26, 128) !important;
    color     : white !important;
}

.create-button:active button {
    border: none !important;
}

.preview-card {
    margin-left: 28%;
    width      : 43%;
    margin-top : 1.2%;
}

@media (max-width: 350px){
    .allActive-card-preview.preview-card {
        margin-left: 28%;
        width      : 73% !important;
        margin-top : 1.2%;
    }
    .preview-card {
        margin-left: 28%;
        width: 70% !important;
        margin-top: 1.2%;
    }
}

.preview-button {
    width: 30%;
    margin-top : 2%;
}

.preview-button-fr{
    width: 35%
}

.card-detail-button.preview-selected-button {
    background-color: #0D1B40 !important;
    color: #FFFFFF !important;
}
@media (max-width: 700px){
    .allActive-card-detail.card-detail-button.preview-selected-button {
        background-color: #0D1B40 !important;
        color: #FFFFFF !important;
        width: 40%;
    }
    .dashboard-zoom-fr.popover-header-span {
        margin-left: 32% !important;
        margin-top: 6px !important;
    }
    .dashboard-tabs-fr {
        margin: 2px -92px 2px 0% !important;
    }
}

.transaction-details {
    color: #333333;
}

.transaction-head {
    color: #19398A;
    font-size: 18px;
    font-weight: bold;
}

.trxTable .ant-pagination-total-text {
    left: 0% !important;
    width: 40%;
    text-align: left;
}

.trxTable .ant-pagination-prev {
    margin-right: 0px;
    margin-left: 79.5% !important;
}

.trx-pagination-button {
    padding: 0px 10px;
}

.trx-card {
    box-shadow: none !important;
    border-radius: 0px !important;
    background: none !important;
}

.trxTable .ant-table-thead > tr > th {
    padding: 10px 16px;
}

.preview-card-bottom {
    color          : rgba(0, 0, 0, .65);
    font-size      : 11px;
    display        : flex;
    justify-content: center;
    margin-top : 5px;
}

.detail-card {
    margin-top: 0 !important;
}

.preview-spin {
    margin-left  : 45%;
    margin-top   : 11%;
    margin-bottom: 15%;
    width        : 30%;
    text-align   : left;
}

.preview-head {
    color      : #19398A;
    font-weight: bold;
}

.preview-details {
    padding      : 2% 4%;
    border       : 3px solid #f5f5f5;
    margin-top   : 2%;
    border-radius: 20px;
    box-shadow: 0px 0px 0px #00000029;
}

.preview-body>div {
    margin-top   : 1%;
    margin-bottom: 0.5%;
}

.preview-body b {
    color: rgba(0, 0, 0, .65);
}

.send-card {
    margin-top   : 4% !important;
    margin-left  : 5% !important;
    color        : #0841C5;
    border       : 1px solid #0841C5;
    border-radius: 5%;
}

.save-card {
    margin-top   : 4% !important;
    margin-left  : 0 !important;
    background   : white !important;
    color        : #0a41c5 !important;
    border       : 1px solid #0a41c5;
    border-radius: 5%;
}

.preview-otp-body {
    margin    : 0 27%;
    text-align: center;
    color     : black;
    font-size : 13px;
}

.preview-otp-body div {
    margin: 0 0 4% 0;
}

.preview-otp-body input {
    border-bottom-color: #adadad;
}

.preview-otp-body>.show {
    margin-left: 6% !important;
}

.preview-otp-error-text {
    color      : #666666 !important;
    font-weight: normal;
}

.ant-tabs-bar{
    border-bottom: 1px solid #666666;
}

.ant-slider-rail{
    background-color: #666666;
}

.ant-spin-dot-item{
    background-color: #0841C5;
}

.MuiChip-deleteIcon.MuiChip-deleteIconMedium{
    color: #333333 !important;
}
.MuiChip-colorDefault.MuiChip-deletable{
    color: #333333 !important;
    background-color: #CDCDD3 !important;
    border: 1px solid #333333 !important;
}
.preview-otp-error-text i {
    color    : #CB4E01;
    font-size: 14px;
    padding  : 0 2%
}

.preview-otp-error-empty {
    padding: 3.2%;
}

.tab-button {
    border    : none;
    background: none;
}

.tab-button:focus b {
    border: #40a9ff 1px solid !important;
}

.cardContent {
    text-align: left;
    font-size : 15px;
    color     : #0C2074;
    opacity   : 1;
}

.graphContent {
    text-align : left;
    font-size  : 16px;
    color      : #0A41C5;
    opacity    : 1;
    font-weight: bold;
}

.dropDownArrow .ant-select-selection {
    border-bottom-width: 0px;
}

.dropDownArrow .ant-select-arrow-icon {
    color      : #0C2074;
    padding-top: 2px;
}

.card-count {
    text-align   : left;
    font-size    : 30px;
    color        : #2C2C2C;
    opacity      : 1;
    margin-bottom: -10px;
}

.cardContent1 {
    text-align: left;
    font-size : 14px;
    color     : #0C2074;
    opacity   : 1;
}

.inside-card {
    margin-top: -10px;
}

.dot1 {
    height          : 12px;
    width           : 12px;
    background-color: #39BF71;
    border-radius   : 50%;
    display         : inline-block;
}

.dot2 {
    height          : 12px;
    width           : 12px;
    background-color: #FEAB4C;
    border-radius   : 50%;
    display         : inline-block;
}

.dot3 {
    height          : 12px;
    width           : 12px;
    background-color: #0A41C5;
    border-radius   : 50%;
    display         : inline-block;
}

.margin-space {
    margin-top: 12px;
}

.bar {
    border-radius: 20px;
    width        : 12px;
    position     : absolute;
    bottom       : -170px;
}

.greyContent {
    color    : #6E6E6E;
    font-size: 12px;
}

.card-title {
    font-style  : normal;
    font-variant: normal;
    font-weight : normal;
    font-size   : 12px;
    line-height : 14px;
    font-family : 'U.S.BankCircularWeb-Regular';
    color       : #666666;
}

.card-value {
    font-style  : normal;
    font-variant: normal;
    font-weight : normal;
    font-size   : 14px;
    line-height : 16px;
    font-family : 'U.S.BankCircularWeb-Regular';
    color       : #333333;
    margin-top  : 5px;
}

/* Laptop & Mac */
@media only screen and (min-device-width : 1366px) and (max-device-width : 1400px) {
    .card-component {
        margin: 6.25em 4% 4% 4%;
    }

    .card-body {
        height: 280px;
    }

    .profile-dropdown {
        margin-left: -35%;
        padding    : 5% 10%;
    }

    .confirm-visible {
        margin-left: 58%;
    }
}


/* iPad Pro */
@media only screen and (min-device-width : 1024px) and (max-device-width : 1365px) {
    .search-text {
        font-size: 22px;
        color: #fff
    }

    .card-component {
        margin: 9% 4% 4% 4%;
    }

    .card-body {
        width : 105%;
        height: 245px;
    }

    .profile-dropdown {
        margin-left: -25%;
        padding    : 5% 10%;
    }

    .user-arrow-right {
        padding-left: 1% !important;
    }

    .user-arrow-left {
        margin-left: -6%;
    }

    .close-icon {
        margin-left: 88%;
        font-size  : 19px;
    }

    .sort-dropdown i {
        margin-left: 15px !important;
    }

    .mimi-card-button {
        padding-left: 40% !important;
    }

    .mimi-card-button span {
        margin: -1%;
    }

    .user-body {
        height: 135px;
    }

    .filter img {
        width       : 55%;
        margin-right: 25%;
        margin-top  : 15%;
    }

    .confirm-visible {
        margin-left: 43%;
    }

    .show-all button {
        font-size: 12px;
    }

    .header-span {
        font-size: 12px;
    }

    .header-span:hover {
        font-size: 12px;
    }

    .line-text {
        font-size: 17px;
    }

    .profile-icon {
        margin-left: 34%;
    }

    .profile-icon i {
        font-size: 28px;
    }

    .profile-dropdown div {
        margin-bottom: 5%;
    }

    .profile-dropdown .profile-icon {
        margin-top: 5%;
        height    : 42px;
        width     : 30%;
    }

    .search-icon {
        margin-top: 2.5%;
        font-size : 20px;
    }

    .blank-details {
        padding  : 12% 27%;
        font-size: 15px;
    }
}

/* iPad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (-webkit-min-device-pixel-ratio : 2) {
    .search-text {
        font-size: 20px;
        color :#ffffff
    }

    .sort-dropdown {
        margin: 0;
    }

    .sort-dropdown span {
        padding: 0;
    }

    .close-icon {
        margin-left: 85%;
        font-size  : 17px;
    }

    .card-component {
        margin: 14% 4% 4% 4%;
    }

    .filter img {
        width       : 68%;
        margin-right: 25%;
        margin-top  : 20%;
    }

    .user-body {
        height: 120px;
    }

    .line-text {
        font-size: 15px;
    }

    .card-body {
        height: 225px;
    }

    .mimi-card-button span {
        font-size: 8px;
    }

    .mimi-card-button {
        padding-left: 30% !important;
    }

    .confirm-visible {
        margin-left: 24%;
    }

    .user-arrow-left {
        margin-left: -7%;
    }

    .user-arrow-right {
        padding-left: 0% !important;
    }

    .show-all button {
        font-size: 11px;
    }

    .header-span {
        font-size: 12px;
    }

    .header-span:hover {
        font-size: 12px;
    }

    .profile-dropdown {
        margin-left: -17%;
        padding    : 1% 0%;
    }

    .profile-dropdown div {
        margin-bottom: 5%;
    }

    .profile-icon {
        margin-left: 37%;
    }

    .profile-icon i {
        font-size: 25px;
    }

    .profile-dropdown .profile-icon {
        margin-left: 37%;
        height     : 39px;
        width      : 25%;
    }

    .search-icon {
        margin-top: 3.5%;
        font-size : 18px;
    }

    .blank-details {
        padding  : 12% 22%;
        font-size: 13px;
    }
}

/* phones */
@media only screen and (max-device-width: 767px) {
    .search-text {
        font-size    : 20px;
        margin-bottom: 0;
        margin-top   : 2%;
        color: #fff;
    }

    .container-header {
        padding-top   : 5%;
        padding-bottom: 10%;
    }

    .search-input {
        margin-top                : 3%;
        border-top-right-radius   : 8px;
        border-bottom-right-radius: 8px;
    }

    .search-icon {
        margin-top: 3.5%;
        font-size : 18px;
    }

    .close-icon {
        margin-left: 85%;
        font-size  : 17px;
    }

    .sort-dropdown-div {
        margin                   : 1% 0 2% 2%;
        border-top-left-radius   : 8px;
        border-bottom-left-radius: 8px;
    }

    .card-component {
        margin-top: 28%;
    }

    .search-button {
        margin-top: 1%;
    }

    .blank-details {
        padding  : 12% 3%;
        font-size: 14px;
    }

    .filter img {
        width     : 150%;
        margin-top: 30%;
    }

    .show-all {
        margin-left: 3%;
    }

    .sort-dropdown {
        margin: 0;
    }

    .sort-dropdown span {
        padding: 0 3%;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .plus-circle-o {
        font-size: 25px;
    }

    .user-arrow-right {
        padding-top : 8%;
        padding-left: 0% !important;
    }

    .right-arrow button {
        width : 20px;
        height: 30px;
    }

    .user-arrow-left {
        padding-top: 8%;
        margin-left: -9%;
    }

    .left-arrow button {
        width : 20px;
        height: 30px;
    }

    .create-card-border {
        margin-left: 1%;
    }

    .card-body {
        width : 235%;
        height: 215px;
    }

    .mimi-card-button {
        padding-left: 20% !important;
    }

    .card-unassign {
        width: 15%;
    }

    .preview-card {
        margin-left: 12%;
        width      : 75%;
    }

    .preview-otp-body {
        margin: 0 5%;
    }

    .preview-otp-body>.show {
        margin-left: 2% !important;
    }

    .preview-spin {
        margin-top   : 25%;
        margin-bottom: 30%;
    }

    .valid-form {
        padding: 11.2% 4%;
    }

    .create-button button {
        margin-top: 20%;
    }

    .phone-number-div select {
        font-size: 9px;
    }

    .phone-number-div {
        margin-top: 6%;
    }

    .phone-number-div p {
        margin-top: -10%;
    }

    .save-card {
        margin-top: 5% !important;
    }

    .create-card-details {
        padding: 5% 0;
    }

    .create-head {
        padding-bottom: 3%;
    }

    .confirm-visible {
        margin-left: 11%;
    }
}

.ant-table-thead>tr>th {
    text-align    : left;
    font          : normal normal medium 16px/19px 'U.S.BankCircularWeb-Regular';
    letter-spacing: 0px;
    color         : #0C2074;
    background    : #F7F7FA 0% 0% no-repeat padding-box;
}

.ant-table-tbody>tr>th {
    text-align    : left;
    font          : normal normal medium 16px/19px 'U.S.BankCircularWeb-Regular';
    letter-spacing: 0px;
    color         : #0C2074;
    background    : #3071b9 !important;
}

.text-green {
    color: #0A853D;
}

.text-red {
    color: #BE1324;
}

.dash_button span {
    color      : #0a41c5;
    font-size  : 16px;
    font-weight: 500
}

.request_button span {
    font-size       : 16px;
    font-family     : U.S.BankCircularWeb-Bold;
    font-weight     : 500;
    background-color: #39bf71;
    padding         : 0px 10px !important;
    color           : white
}
.ant-btn>span {
    padding: 0px 30px;
    letter-spacing: 1px;
}
@media(max-width: 350px){
    .allActive-zoom-deact.ant-btn>span {
        padding: 0px;
        letter-spacing: 1px;
    }
    .ant-modal.confirm-visible.deactivate-modal.deact-modal-zoom{
        margin-left: 13% !important;
        width: 262px !important;
    }
    .myReq-underline-fr {
        width: 97% !important;
    }
}


@media only screen and (max-width: 414px) {
    .container-header {
        /* height       : 100px !important; */
        margin-bottom: 40px;
    }

}

*:focus {
    outline        : dotted 2px black !important;
    text-decoration: none;
    outline-offset : 2px;
    outline-style  : dotted;
}

.bulk-close {
    color         : white;
    position      : relative;
    bottom        : 25%;
    right         : 5px;
    padding-bottom: 0%;
}

.approval-close{
    margin: 40%
}

.request_button,
.request_button :hover,
.request_button :focus {
    background-color: #39bf71;
    height          : 40px;
    font-style      : normal;
    font-variant    : normal;
    font-weight     : normal;
    font-size       : 20px;
    line-height     : 25px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    top: 7px;
}
.ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
    color: #555555 !important;
    background-color: #CDCDD3 !important;
    border-color:#CDCDD3 !important;
}

  
  span[role="link"]:focus , span[role="listitem"]:focus, .active-card-button:focus, .dashboard-none:focus {
    outline: 2px dotted white !important;
    text-decoration: none;    
    outline-offset: 2px;
    outline-style: dotted;
  }
  div[role="link"]:focus {
    outline: 2px dotted black;
  }
  button[role="button"]:focus,.create-card-button:focus, .export-button:focus   {
    outline: 2px dotted black;
  }
  .createNewUserProfile-Inputs{
      border-bottom: 1px solid #666666 !important;
  }

 .retry-card:hover, .send-otp-btn:hover,
  .retry-card:focus, .retry-card>span{
    color: #ffffff !important;
    border-color: #ffffff !important;
  }
  .ant-btn.retry-card.ant-btn-background-ghost{
      border-color: white !important;
  }

  .send-otp-btn.ant-btn.ant-btn-background-ghost:focus
  ,  .retry-card.ant-btn.ant-btn-background-ghost:focus,
  .req-close-btn:focus, .req-back-btn:focus{
    
    outline: 2px dotted white !important;
  }

  .ant-btn.send-card:focus, .ant-btn.send-card:hover{
    color        : #0841C5;
    border       : 1px solid #0841C5;
    border-radius: 5%;
  }
  
  .card-tag {
    font-size: 13px;
    padding  : 2px;
    top      : 10px;
    color: #ffffff;
}

    .language-option{
        text-align : center;
    }

    .ant-upload-list{
        border: 1px solid black;
        margin-top: 30px;
        width: 70%;
        margin-left: 15%;
    }

    .uploaded-file{
        color: rgb(25, 57, 138);
        position: absolute;
        text-overflow: ellipsis;
        padding-right: 14px;
        margin-left: 25px;
        width: 90%;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 5px;
    }

    .deleteIcon{
        color: #666666;
        margin-left: 27%;
        width: inherit;
    }

    .text-area,  .text-area:hover {
        height: 32px !important;
        border-radius: 0px;
        margin-top: 4px;
        border: none;
        border-top: 1px solid #E7E7EB;
    }

    .delete-btn.ant-btn-primary{
        background-color: #c92323 !important;
        border-color: #c92323 !important;
    }

    .download-record, .download-record:focus, .download-record:hover{
        height: 20%;
        color: #19398A !important;
        cursor: pointer !important;
        opacity: 1;
        font-weight: 600 !important;
        font-size: 13px !important;
        float: right;
        margin-top: -22px !important;
        margin-bottom: 10px;
        width: 20%;
        background-color: transparent;
        border:none !important;
    }

    .download-record.ant-btn[disabled], .download-record.ant-btn[disabled]:hover{
        
        background-color: transparent !important;
        border:none !important;
        cursor: not-allowed  !important;
    }

    .ant-pagination-total-text{
        width: 30%;
        left: 11% !important;

    }

    .total-text-pagination{
        margin-left:-10%;
        float: left;
    }

    .page-count-span{
        float:right;
        margin-left: 39%;
        margin-right: -5%;
    }

    .page-count-span-bulk{
        float:right;
        margin-right: -35%;
    }

    .ant-select-dropdown-menu-item-active{
        left: 3px;
        width: 97%;
    }

    .text-area:focus, .ant-radio-button-wrapper:focus-within, .card-detail-button.Mui-focusVisible,
    .singleType.Mui-focusVisible, .bulkType.Mui-focusVisible,
    .req-tab.Mui-focusVisible span,  .approv-tab.Mui-focusVisible span, 
    .reject-tab.Mui-focusVisible span,.req-tab.Mui-focusVisible div,  .approv-tab.Mui-focusVisible div, 
    .reject-tab.Mui-focusVisible div, .fail-tab.Mui-focusVisible div, .pass-tab.Mui-focusVisible div,
    .upload-tab.Mui-focusVisible div, .failed-tab.Mui-focusVisible div, .process-tab.Mui-focusVisible div,
    .comp-tab.Mui-focusVisible div, .ant-select-dropdown-menu-item-active,
    .single-tab.Mui-focusVisible span, .bulk-tab.Mui-focusVisible span {
        
        outline: 2px dotted black !important;
    }

    .req-tab.Mui-focusVisible.Mui-selected , .approv-tab.Mui-focusVisible.Mui-selected , 
    .reject-tab.Mui-focusVisible.Mui-selected , .pass-tab.Mui-focusVisible.Mui-selected 
    .fail-tab.Mui-focusVisible.Mui-selected ,.upload-tab.Mui-focusVisible.Mui-selected ,
    .failed-tab.Mui-focusVisible.Mui-selected , .process-tab.Mui-focusVisible.Mui-selected ,
    .comp-tab.Mui-focusVisible.Mui-selected,
    .req-tab.Mui-selected:focus , .approv-tab.Mui-selected:focus , 
    .reject-tab.Mui-selected:focus , .pass-tab.Mui-selected:focus, 
    .fail-tab.Mui-selected:focus ,.comp-tab.Mui-selected:focus ,
    .upload-tab.Mui-selected:focus , .failed-tab.Mui-selected:focus ,
    .process-tab.Mui-selected:focus , .bulk-tab.Mui-selected:focus, .single-tab.Mui-selected:focus,
    .bulk-tab.Mui-focusVisible.Mui-selected, .single-tab.Mui-focusVisible.Mui-selected
    {
        outline:  none !important;
    }

    .upload-tab, .failed-tab, .process-tab, .comp-tab{
        border-color: #666666 !important;
        color: #333333 !important;
    }
    .disable-input.credit-card-startDate{
        border-bottom: 1px #666666 solid !important;
        color: #333333 !important;
        opacity: 0.6 !important;
    }

    .create-card-details-body.card-valid-from .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
        opacity: 0.5!important;
    }

    .req-tab, .approv-tab, .reject-tab, .fail-tab, .pass-tab{
        font-size: 16px !important;
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
        color: #666666 !important;
    }

    .cards-tab{
        font-size: 23px !important;
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
        color: #0c2074 !important;
        font-weight: 500 !important;
        background-color: transparent !important;
    }

    /* zoom level 400% */
    @media(max-width:350px){
        .confirm-visible.filter-modal-zoom .ant-modal-title:before{
            bottom: 85% !important;
        }
        .allActive-zoom-400.cards-tab{
            font-size: 23px !important;
            text-transform: none !important;
            font-family: "U.S.BankCircularWeb-Regular" !important;
            color: #0c2074 !important;
            font-weight: 500 !important;
            background-color: transparent !important;
            padding-bottom: 30px;
        }
    }

    .activeCards-extra .MuiTabs-indicator,
    .activeCards-tabs .MuiTabs-indicator{
        background-color: transparent !important;
    }

    .req-tab.Mui-selected, .approv-tab.Mui-selected, .reject-tab.Mui-selected,
    .fail-tab.Mui-selected, .pass-tab.Mui-selected{
        color: #19398A !important;
        font-weight: 600;
    }

    .MuiTabs-indicator{
        background-color: #19398A !important;
    }

    .request-extra-sub .MuiTabs-indicator{
        background-color: transparent !important;
    }

    .upload-tab, .failed-tab, .process-tab, .comp-tab{
        font-size: 16px !important;
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
        /* color: #666666 !important; */
        border: 1px solid #666666 !important;
        border-radius: 4px !important;
        margin-left: 18px !important;
        background-color: #E4E4E6 !important;
        min-height: 0px !important;

    }
    .anticon.anticon-arrow-right, .anticon.anticon-arrow-left{
        color: #666666;
        font-family: 'U.S.BankCircularWeb-Regular';
    }

    .ant-btn.ant-btn-primary.bulkUpload-save, .ant-btn.validate-btn.validate-btn-zoom.ant-btn-primary{
        background-color: #19398A !important;
        color: #fff !important;
    }

    .upload-tab.Mui-selected, .failed-tab.Mui-selected, .process-tab.Mui-selected, .comp-tab.Mui-selected{
        color: #19398A !important;
        background-color: #E8EEFF !important;
        font-weight: 600;
        border: 1px solid #19398A !important;
    }
    .ant-slider-track{
        background-color: #19398A !important;
    }

    .singleType, .bulkType{
        border-radius: 0px !important;
        width: 2px;
        height: 2px;
    }


    .suggestion-text-area{
        margin-left: 84%;
        color: #666666;
        font-size: 14px;
    }

    .ant-btn.upload-btn.ant-btn-primary{
        width: 20%;
        background-color: #19398a;
        border-color: #19398a;
    }

    .ant-btn.upload-btn[disabled] , .ant-btn.upload-btn[disabled]:hover {
        color: #555555 !important;
        background-color: #CDCDD3 !important;
        border-color:#CDCDD3 !important;
    }

    .create-card-button{
        background-color: #19398a !important;
        border-color: #19398a !important;
        height: 40px !important;
        font-size:16px !important;
        top:7px !important;
        font-weight: 500 !important;
        margin-left:10px !important; 
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
        letter-spacing: 1px !important ;
    }

    .export-button{
        background-color: #19398a !important;
        border-color: #19398a !important;
        height: 38px !important;
        font-size:14px !important;
        top:7px !important;
        font-weight: 500 !important;
        margin-left:10px !important; 
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
        letter-spacing: 1px !important ;
    }
    .ant-slider-handle{
        border: solid 2px #19398A !important;
    }
    :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up, :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down{
        color: #666666 !important;
    }

    .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on{
        color: #19398A !important;
    }
    .active-card-button{
        background-color: transparent !important;
        border-color: none !important;
        /* height: 40px !important; */
        font-size:14px !important;
        top: -5.5px !important;
        font-weight: 500 !important;
        margin-left: -25px !important; 
        text-transform: none !important;
        font-family: "U.S.BankCircularWeb-Regular" !important;
    }
    .uploadFile-container{
        border: 1px solid #666666; 
        margin-top: 20px; 
        position: relative;
        width: 70%; 
        height: 100%; 
        margin-left: 15%;
    }

    .ActiveCard-menuItem{
        left: 5px;
        width: 122px;
    }
    .ant-input-search-icon{
        color: #666666 !important;
    }

    @media (max-width: 700px){
        .anticon.anticon-arrow-left.req-back-btn{
            margin-top: -14px !important;
        }
        .req-close-btn.anticon.anticon-close-circle {
            margin-top: -14px !important;
        }
        .allActive-zoom-export.export-button{
            background-color: #19398a !important;
            border-color: #19398a !important;
            height: 38px !important;
            font-size:14px !important;
            top:8px !important;
            font-weight: 500 !important;
            margin-left:10px !important; 
            text-transform: none !important;
            font-family: "U.S.BankCircularWeb-Regular" !important;
            letter-spacing: 1px !important ;
            left: 120px;
        }
        .deleteIcon{
            color: #666666;
            margin-left: 23%;
            width: inherit;
        }
        .create-card-button {
            top: -37px !important;
            left: 44px !important;
        }
    }

    @media (max-width: 350px){
        .create-card-button {
            top: -29px !important;
            left: 44px !important;
        }
        .allActive-zoom-export.export-button{
            background-color: #19398a !important;
            border-color: #19398a !important;
            height: 38px !important;
            font-size:14px !important;
            top: 18px !important;
            font-weight: 500 !important;
            margin-left:10px !important; 
            text-transform: none !important;
            font-family: "U.S.BankCircularWeb-Regular" !important;
            letter-spacing: 1px !important ;
            left: 20px;
        }
        .uploadFile-container{
            border: 1px solid #666666; 
            margin-top: 20px; 
            position: relative;
            width: 90%; 
            height: 100%; 
            margin-left: 5%;
        }
        .uploaded-file{
            color: rgb(25, 57, 138);
            position: absolute;
            text-overflow: ellipsis;
            padding-right: 14px;
            margin-left: 25px;
            width: 80%;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            margin-top: 5px;
        }
        .deleteIcon{
            color: #666666;
            margin-left: 23%;
            width: inherit;
        }
        .create-card-btn.bulkUpload-create-card.ant-btn-primary[disabled], .create-card-btn.bulkUpload-create-card.ant-btn-primary{
            margin-top: 40px;
            /* left: -63px; */
            width: auto !important;
        }
    }

    .Req-table.userlist.paginationAlign.pageArrowBulk .page-count-span{
        margin-right: -30%;
    }


    .browse-file-btn,
    .browse-file-btn:focus,
    .browse-file-btn:hover  {
        color: #19398a !important;
        margin-top: 25% !important;
        border: none !important;
        background-color: transparent !important;
        font-weight: bold;
        font-size:  18px;
    }

    .title.ant-table-column-has-actions.ant-table-column-has-sorters{
        align-items: center;
        text-align: center !important;
    }

    .ant-upload p{
        color: #333333 !important;
    }
    

    .anticon.anticon-search.ant-input-search-icon{
        cursor: text;
    }

    .sent-for-approval-modal button{
        margin-top: 10px;
        width: 30px;
        height: 20px;
    }

    .sent-for-approval-modal span{
        width: 26px;
        height: 26px;
    }

    .bulk-tableth-name-tabs{
        font-weight: 700 !important;
        color: #333333 !important;
    }

    .active-cards-dropdown .anticon.anticon-down.ant-select-arrow-icon{
        color: #ffffff;
        margin-top: -3px;
        margin-right: -4px;
    }

    .card-detail-button {  
        line-height: 1.5 !important;
        display: inline-block !important;
        font-weight: 400 !important;
        text-align: center !important;
        -ms-touch-action: manipulation !important;
            touch-action: manipulation !important;
        cursor: pointer !important;
        background-image: none !important;
        border: 1px solid transparent !important;
        white-space: nowrap !important;
        padding: 0 25px !important;
        font-size: 14px !important;
        border-radius: 4px !important;
        height: 32px !important;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative !important;
        color: #0D1B40 !important;
        background-color: #D7D8DB !important;
        border-color: #0D1B40 !important;
        min-height: 0px !important;
        text-transform: none !important;
        margin-top:2% !important;
        -webkit-flex-direction: row !important;
        flex-direction: row !important;
     }
    @media(max-width: 700px){
        .card-detail-button {  
            line-height: 1.5 !important;
            display: inline-block !important;
            font-weight: 400 !important;
            text-align: center !important;
            -ms-touch-action: manipulation !important;
                touch-action: manipulation !important;
            cursor: pointer !important;
            background-image: none !important;
            border: 1px solid transparent !important;
            white-space: nowrap !important;
            padding: 0 25px !important;
            font-size: 14px !important;
            border-radius: 4px !important;
            height: 32px !important;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            position: relative !important;
            color: #0D1B40 !important;
            background-color: #D7D8DB !important;
            border-color: #0D1B40 !important;
            min-height: 0px !important;
            text-transform: none !important;
            margin-top:2% !important;
            -webkit-flex-direction: row !important;
            flex-direction: row !important;
            width: 40%;
         }
         .otp-button.ant-btn-primary[disabled]{
             left: 0px !important;
         }
    }

    @media (max-width: 1190px){
        .approv-bulk-pass-footer{
            margin-left: -6% !important;
        }
        .req-activetab{
            left: 42% !important;
        }
    }
    /*From Zoom 175%*/
    @media (max-width: 991px){
        
        .Req-table.userlist .total-text-pagination{
            margin-left: 5%;
            /* font-size: 8px; */
        }
        .total-text-pagination{
            margin-left: -1%;
            /* font-size: 8px; */
        }
        .page-count-span{
            margin-left: 1%;
            /* font-size: 8px; */
            margin-right:9%;
        }
        .ant-table-pagination.ant-pagination{
            
            margin: 25px 0;
        }
        .ant-drawer-content-wrapper{
            width: 500px !important;
        }
        .create-card-details-body .disable-input{
            padding: 0 57% 1.5% 3%;
        }
        .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper{
            width: 30% !important;
        }
        .otp-input input{
            width: 15% !important;
        }
        .MuiTabs-root.request-extra-sub{
            margin-left: -1% ;
        }
        .request-extra{
            margin-top: -30px !important;
        }
        .myRequest-extra-bulk{
            left: 50% !important;
        }
        
        .phone-number-div select{
            width: 30% !important;
        }
        .deactivate-modal .ant-modal-footer .cancel-btn-ant, .upload-btn.ant-btn-primary[disabled]{
            left: 0 !important;
        }

        .edit-btn.filter-comp{
            left:  15px !important;
        }
        .create-more.filter-comp{
            left: -65px !important;
        }
        
        .upload-btn.ant-btn-primary{
            width: 30% !important;
        }

        .bulk-upload.cancel-btn-ant{
            left: 9% !important;
        }
        .ant-card-pv .paginationAlign .ant-table-pagination.ant-pagination{
            width: 90% !important;
        }
        .download-record{
            margin-top: 1px !important;
            width: auto !important;
        }

        .deactivate-footer{
            width: 85% !important;
        }

        .allActive-zoom-deact{
            left: -50px !important;
        }

        .ant-btn.card-detail-cancel{
            width : 55% !important;
        }

        .ant-btn.card-detail-save{
            width :  55% !important;
        }

        .trxTable .ant-pagination-total-text {
            left: -0% !important;
            width: 40%;
            text-align: left;
        }
        
        .trxTable .ant-pagination-prev {
            margin-right: 0px !important;
            margin-left: 69.5% !important;
        }

        .ant-btn.card-detail-save-zoom{
            width :  69% !important;
        }

        .dashboard-chart-x{
            overflow: auto;
        }
    }

    @media (max-width: 791px){
        .ant-tooltip-arrow, .ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow{
            position:fixed;
        }
        .ant-modal.confirm-visible.filter-modal{
            position: fixed;
            top: 13px !important;
            left: 86px !important;
        }
        .ant-modal-wrap .ant-modal.confirm-visible.filter-modal .ant-modal-footer{
            padding: 10px 0px !important;
        }
        .container-header{
            height: 252px;
        }
        .header-span{
            margin-left: 1% !important;
        }
        .ant-pagination-total-text{
            font-size: 14px;
        }
        .myrequest-filter{
            height: 40px !important;
            top: 3px !important;
        }
        .bulk-close{
            bottom: 14% ;
        }
        .req-back-btn{
            margin-left: -1.5%;
        }
    }

    @media (max-width: 500px){
        .ant-pagination-total-text{
            font-size: 14px;
        }
    }

    /* Zoom 300-400*/
    @media (max-width: 400px){
        .Req-table.userlist .total-text-pagination {
            margin-left: -33% !important;
            /* font-size: 8px; */
        }
        .ant-modal-title{
            line-height: 17px !important;
        }
        .ant-modal-body{
            padding: 0px 11px !important;
        }
        .ant-modal-wrap .ant-modal.confirm-visible.filter-modal .ant-modal-footer {
            padding: 2px 0px 0px!important;
        }
        .ant-modal-wrap .ant-modal.confirm-visible.filter-modal .ant-modal-footer button {
            /* margin-left: -23px; */
            padding: 0px 0px 0px 0px;
        }
        .card-details p {
            padding-top: 29%;
            padding-left: 12%;
            font-weight: 700;
        }
        .ant-btn.retry-card.ant-btn-background-ghost {
            left: -42px !important;
            top: -12px !important;
        }
        .myRequest-extra, .myRequest-extra-bulk{
            left: 8% !important;
            width: 48% !important;
        }
        .myrequest-filter{
            height: 32px !important;
            top: 3px !important;
        }
        .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper{
            width: 55% !important;
        }
        .ant-drawer-content-wrapper{
            width: 300px !important;
        }
        .dropDownArrow-approver{
            font-size: 10px !important;
        }
        .MuiButtonBase-root.MuiIconButton-root{
            padding: 0px !important;
        }
        .phone-number-div select{
            width: 55% !important;
        }
        .phone-number-div input{
            width: 110%;
        }
        .ant-table-pagination.ant-pagination{
            
            margin: 60px 0;
        }
        .total-text-pagination{
            margin-left: -25%;
            /* font-size: 8px; */
        }
        .page-count-span{
            margin-left: 1%;
            /* font-size: 8px; */
            margin-right: 19%;
        }
        .myApproval-extra.myActiveCards-extra .ant-col.ant-col-12{
            left: 29% !important;
        }

        .myApproval-extra.myActiveCards-extra .search-input.search-input-suffix {
            left: -50px;
            width: 114% !important;
        }

        .confirm-visible.filter-modal .ant-modal-footer .ant-btn, .deactivate-modal .ant-modal-footer .ant-btn{
            margin-bottom: 4%;
        }
        .allActive-card-detail.card-detail-button, .card-detail-button {
            width: 45% !important;
            padding: 0 15px !important;
        }

        .tab-div{
            margin-left: 6% !important;
        }
        .req-back-btn{
            display: none;
        }

        .edit-btn.filter-comp, .delete-btn.filter-comp{
            left: 25% !important;
        }

        .create-more.filter-comp{
            left: 0 !important;
        }

        
        .upload-btn.ant-btn-primary{
            width: 50% !important;
        }
        .bulk-upload.cancel-btn-ant{
            left: 0 !important;
        }

        .paginationAlign .ant-pagination-prev, .ant-pagination-prev{
            margin-left: 37% !important;
        }

        .allActive-title-col{
            margin-top: 30% !important;
        }

        .allActiveCards-extra.zoom-400{
            left: 30% !important;
        }

        .deactivate-footer{
            width: 63% !important;
        }

        .allActive-cancel{
            left: 0 !important;
        }

        .allActive-zoom-deact{
            margin-top: 20% !important;
            left: 0 !important;
        }

        .deactivate-col{
            margin-left: 25% !important;
        }

        .ant-btn.card-detail-cancel{
            width : 95% !important;
            margin-left: -4% !important;
        }

        .card-detail-save.ant-btn{
            width :  95% !important;
            margin-top  :   5px !important;
        }

        .ant-modal.create-holder-details.confirm-visible {
            width: 93% !important;
        }
        .otp-input input {
            width: 27% !important;
        }
        .ant-modal.confirm-visible.deactivate-modal{
            width: 93% !important;
        }
        .confirm-visible.filter-modal .ant-modal-footer .ant-btn, .deactivate-modal .ant-modal-footer .ant-btn{
            margin-bottom: 3% !important;           
            width: 60%!important;
        }
        .ant-btn>span {
            padding: 0px 0px;
            letter-spacing: 0px;
        }
        .blk-upload-zoom.ant-modal.confirm-visible.filter-modal {
            position: fixed;
            top: 3px !important;
            left: 44px !important;
            /* margin: 0 auto !important; */
        }
        .actCard-filter-zoom.ant-modal.confirm-visible.filter-modal {
            position: fixed;
            top: 2px !important;
            left: 44px !important;
            width: 270px !important;
            /* margin: 0 auto !important; */
        }
        .messageCard-zoom {
            margin-top: -44px!important;
            margin-bottom: 0!important;
        }
        .message-zoom{
            margin-top: -15px!important;
            margin-bottom: 0em !important;
        }
        .ant-slider.messageCard-slider, .message-zoom.ant-slider{
            margin: 1px 6px 16px !important;
        }
        .ant-tooltip-inner {
            /* background: transparent !important; */
            margin-left: -4px !important;
            padding: 2px 2px!important;
        }
        .ant-modal-body {
            padding: 5px 11px !important;
        }
        .msg-blkupload-zoom{
            margin-top: -12px;
            margin-bottom: 0em;
        }
        .dashboard-zoom-fr.popover-header-span {
            margin-left: 26%!important;
            margin-top: 6px!important;
        }
        .header-span {
            margin-left: -10%!important;
        }

        .trxTable .ant-pagination-total-text {
            left: -0% !important;
            width: 50%;
            text-align: left;
        }
        
        .trxTable .ant-pagination-prev {
            margin-right: 0px !important;
            margin-left: 250.5% !important;
        }
        .trxTable .ant-pagination-next {
            margin-right: 0px !important;
            margin-left: 370.5% !important;
        }
    }

    .MsoNormal, .WordSection1 span, .WordSection1 ul{
        font-family: "U.S.BankCircularWeb-Regular" !important;
        font-size: 14px !important;
    }

    .create-card-details-body.pass-reset-body div{
        margin-top: 0px;
    }

    .trxTable thead{
        background-color: #F7F7FA;
        border: 1px solid #F7F7FA;
    }

    /* .ant-card-pv.trx-card{
        border: none;
    }

    .trxTable .ant-table-tbody>tr{
        border: 1px solid #E7E7EB;
    }

    .trxTable table{
        border-collapse: collapse;
    } */

    .total-text-pagination.myApprover-page{
        margin-left: 5px !important;
    }

    .confirm-visible.deactivate-modal.success-box .ant-modal-header{
        display: none !important;
    }

    .MuiList-root.MuiList-padding.MuiMenu-list{
        padding-left: 1.5px !important;
        padding-right: 1.5px !important;
    }

    .ant-pagination ul{
        padding-top:3px !important;
        padding-bottom : 3px !important;
    }

    .ant-input[disabled], .MuiInputBase-input.MuiInput-input.Mui-disabled{
        color: #333333 !important;
        opacity: 0.6 !important;
        -webkit-text-fill-color: #333333 !important;
    }

    #Logout-div {
        font-family: 'U.S.BankCircularWeb-Regular';
    }
