.transaction-chat-history {
  font-family: 'U.S.BankCircularWeb-Bold' !important;
}

.transaction-chat-history .message-container {
  max-height: 500px;
  overflow-y: scroll; 
}

.transaction-chat-history .message-bubble-container{
  display: block;
  width: 100%;
  position: relative;
  clear: both;
}

.transaction-chat-history .message{
  margin-top: 10px;
  display: block;
  position: relative;
  right: 0px;
}

.transaction-chat-history .message.user-message{
  text-align: right;
}

.transaction-chat-history .message .message-body{
  padding: 10px;
  font-size: 12px;
  display: inline-block;
  max-width: 80%;
  word-break: normal;
  overflow-wrap: break-word;
  border-radius: 10px;
  text-align: left;
}

.transaction-chat-history .message .message-icon{
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
}

.transaction-chat-history .message.chatbot-message .message-icon{
  margin-right: 5px
}

.transaction-chat-history .message.user-message .message-icon{
  margin-left: 5px
}

.transaction-chat-history .message.chatbot-message .message-body{
  background-color: #e7e9f1;
}

.transaction-chat-history .message.user-message .message-body{
  background-color: #666666;
  color: white;
}