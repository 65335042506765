.reportclass{
    background-color: white;
    border-radius: 12px;
    padding-bottom: 5%;
    margin-left: 2%;
    height: fit-content;
}
.reportrule{
    color: #999999;
    font-weight: normal;
    margin-top: 2%;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 22px;
}

.a-link{
    color: #1F337C;
    text-decoration: underline;
}

.a-link:hover{
    color: #1890ff;
}