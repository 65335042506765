.transaction-details{
  background-color: white;
  font-family: 'U.S.BankCircularWeb-Bold' !important;
}

.transaction-details .ant-table table{
  table-layout: fixed;
  font-size: 12px;
}

.transaction-details .ant-table table thead.ant-table-thead th{
  border-right: 0px;
}
.transaction-details .ant-table table tbody.ant-table-tbody td{
  text-align: center;
}

.transaction-details form input.ant-input,
.transaction-details form .ant-select-selection,
.transaction-details form .ant-input-number{
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  margin: 0px;
}

.transaction-details form input.ant-input:focus,
.transaction-details form .ant-select-selection:focus,
.transaction-details form .ant-input-number-input:focus{
  outline: none;
  box-shadow: none;
}

.transaction-details form input.ant-input:hover, 
.transaction-details form input.ant-input.ant-calendar-picker-input:hover,
.transaction-details form .ant-select-selection:hover,
.transaction-details form .ant-input-number:hover{
  border-color: #112A70;
}

.transaction-details form .has-error input.ant-input:hover, 
.transaction-details form .has-error input.ant-input.ant-calendar-picker-input:hover,
.transaction-details form .has-error .ant-select-selection:hover,
.transaction-details form .has-error .ant-input-number:hover{
  border-color: #f5222d;
}

.transaction-questionnaire-form .radio span.ant-radio .ant-radio-inner{
  background-color: white;
  border-color: #ababab;
}

.transaction-questionnaire-form .radio span.ant-radio.ant-radio-checked .ant-radio-inner{
  border-color: #112A70;
  background-color: white;
}
.transaction-questionnaire-form .radio span.ant-radio.ant-radio-checked .ant-radio-inner::after{
  background-color: #112A70;
}
.csr_input:disabled{
  background:#f5f5f5;
  color:#828282;
}
.csr_datepicker input{
  background:#f5f5f5 !important;
  color:#828282 !important;  
}
.ant-input {
  font-size: 14px !important;
}
.ant-calendar-input {
  font-size: 14px !important;
}


