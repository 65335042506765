/* Css for Forgot password MUI select element */
.sendotp-form{
    width: 100% !important;
}

.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined, .MuiSelect-iconOpen.MuiSelect-iconOutlined{
    top: calc(50% - 0.7em) !important;
    font-size : 2.5rem !important;
}

.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
    
    /* padding           : 4px 11px !important;
    margin-top        : 23px !important; */
    color             : #333333 !important;
    font-family: "U.S.BankCircularWeb-Regular";
    /* font-family       : "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "U.S.BankCircularWeb-Regular", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif !important;     */
    font-size         : 14px !important;
    text-align        : left;
}

/* .css-nnbavb{
    border-bottom: 0px !important;
} */

.MuiOutlinedInput-notchedOutline{
    top: -23px !important;
    margin-top: 24px !important;
    height: 32px !important;
    border-color: #737373 !important;
    border-radius: 0px !important;
    border-top        : 1px solid #737373 !important;
    border-left       : 1px solid #737373 !important;
    border-right      : 1px solid #737373 !important;
    border-bottom     : 1px solid #737373 !important;
    padding: 0 135px !important;
}



/* Css for MUI datepicker element*/

.blue-border.MuiTextField-root{
    width: 90%;
    z-index: 1;
}

.MuiInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd{
    border:none !important;
    padding      : 0 5% 1.5% 3% !important;
    font-size    : 13px !important;
    height : 20px !important;
}

.MuiInputAdornment-root{
    margin-bottom: 9px !important;
}

.MuiButtonBase-root.MuiIconButton-root{
    padding : 23px !important;
    /* color : #cacaca !important; */
    border-radius: 0% !important;
    background-color : transparent !important
}

.MuiButtonBase-root.MuiIconButton-root:hover,
.MuiButtonBase-root.MuiIconButton-root:focus,
.MuiButtonBase-root.MuiIconButton-root:active{
    background-color : transparent !important;
}


.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused.MuiInputBase-formControl{
    outline: 2px dotted black !important;
    background-color: transparent !important;
}

.blue-border::-moz-placeholder {
	color: #666666 !important;
	opacity: 1
}

.blue-border:-ms-input-placeholder {
	color: #666666 !important;
}


.blue-border::-webkit-input-placeholder {
	color: #666666 !important;
}

.MuiButtonBase-root.MuiPickersDay-root{
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus,
.PrivatePickersYear-yearButton.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected:focus{
    background-color : #1565c0 !important;
    color: white !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-disabled,
.MuiButtonBase-root.MuiPickersDay-root.Mui-disabled:focus,
.PrivatePickersYear-yearButton.Mui-disabled,
.PrivatePickersYear-yearButton.Mui-disabled:focus{
    color: #555555 !important;
    background-color: #CDCDD3 !important;
    border-color:#CDCDD3 !important;
}


.MuiButtonBase-root.MuiPickersDay-root:hover,
.MuiButtonBase-root.MuiPickersDay-root:focus,
.MuiButtonBase-root.MuiPickersDay-root:target,
.MuiButtonBase-root.MuiPickersDay-root:active,
.MuiButtonBase-root.MuiPickersDay-root:focus-visible,
.MuiButtonBase-root.MuiPickersDay-root:focus-within,
.PrivatePickersYear-yearButton:focus,
.PrivatePickersYear-yearButton:hover,
.MuiButtonBase-root.MuiPickersDay-root:visited,
.PrivatePickersYear-yearButton:visited{
    background-color : #cacaca !important;
}