.trip-details {
  font-family: 'U.S.BankCircularWeb-Bold' !important;
}

.trip-details .body{
  background-color: white
}

.trip-details .body .ant-row:not(:last-child){
  border-bottom: 1px solid #e4e0e0;
}

.ant-row.trip-information, .ant-row.trip-media{
  padding: 10px;
  word-break: break-all;
  font-size: 13px;
  padding-left: 20px;
}

.ant-row.trip-information .ant-col-24, .ant-row.trip-media .ant-col-24{
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ant-row.trip-information .label{
  font-style: italic;
  color: black;
  font-weight: normal;
  font-size: 12px;
}

img.transaction-receipt-image{
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.ant-row.trip-media .ant-col-24.transaction-receipts{
  margin-bottom: 10px;
}

.ant-row.trip-media .flight div.flight-document, .ant-row.trip-media .hotel div.hotel-document{
  color: #455692;
  font-size: 12px;
}

.ant-row.trip-media .flight div.flight-document i, .ant-row.trip-media .hotel div.hotel-document i{
  font-size: 14px;
  vertical-align: middle;
}

.ant-row.trip-media button.download-button{
    background: none !important;
    color: #455692;
    border: none; 
    padding: 0 !important;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;
}

.ant-row.trip-media button.download-button:focus{
  outline: none;
  box-shadow: 0;
}