.carousel-custom-class{
  margin-top: 10%;
}
.container-fluid{
    background-color: #F7F7FA;
}
.navigation-button{
    color: rgb(17, 42, 112);
    font-size: 45px;
    margin-top: 43%;
    margin-left: 19%;
}
.card-class{
    width: 180px;
    height: 100px;
    border: 2px solid #0A41C5;
    border-radius: 10px;
    margin-left: 3%;
    margin-right: 2%;
    margin-top: 6%;
    margin-bottom: 6%;
}

.slick-active.slick-current .setting-card{
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

.card-cover-class{
    font-size: 45px;
    text-align: center;
   /*  margin-left: 38%; */
    margin-top: 6%;
    margin-top: 5% !important;
    margin-bottom: -3% !important;
    height: 48px;
}

.ant-card-meta{
    margin-top: -7%;
    width: 150%;
    margin-left: -22%;
}

.slick-current div.ant-card.ant-card-bordered  i{
    color: white;
}
.slick-current div.ant-card{
    background-color: #0A41C5;
}
.slick-current div.ant-card.ant-card-bordered  div.ant-card-meta-title{
    text-align: center;
    font-size: 13px;
    white-space: initial;
    /*-o-text-overflow: ellipsis;
     text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
    font-weight: 500;
    color:white;
}
.slick-slide.slick-active:not(.slick-current) div.ant-card.ant-card-bordered  div.ant-card-meta-title{
    white-space: initial;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #0A41C5;
}
.slick-slide.slick-active:not(.slick-current)  div.ant-card {
    background-color: white;
    }
.slick-slide.slick-active:not(.slick-current)  div.ant-card.ant-card-bordered  i{
        color: #0A41C5;
    }

 .slick-prev:before, .slick-next:before {
    color: #0A41C5;
}
div.slick-slider.slick-initialized div.slick-prev:before, div.slick-next:before{
    content: ''
}

div.slick-prev i, div.slick-next i {
    font-family: 'slick';
    font-size: 45.8px;
    line-height: 1;
    opacity: 1;
    color: #1F337C;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
div.slick-slider div.slick-arrow.slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -85%) rotate(90deg);
    -ms-transform: translate(0, -85%) rotate(90deg);
    transform: translate(0, -85%) rotate(90deg);
    cursor: pointer;
    color: #112A70;;
    border: none;
    outline: none;
    background: transparent;
}
div.slick-slider div.slick-arrow.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    cursor: pointer;
    color: #112A70;
    border: none;
    outline: none;
    background: transparent;
} 
.prevArrows{
    display: block;
    background: #f2f2f2;
    margin-left: -5%;
}
.slick-next {
    right: -25px;
}
.test {
    margin-left: 6%;
}
@media all and (max-width:770px){
    .prevArrows{
        display: block;
        background: #f2f2f2;
        margin-left: 0%;
        }
        .slick-prev {
            left: -16px;
        }
  }
@media all and (max-width:500px){
    .slick-next {
        right: 12px;
        background: none !important;
    }   
    .card-class{
        margin-left: 9%;
        margin-right: 2%;
    }
    .test {
        margin-left: 13%;
    }
}
@media all and (max-width:320px){
  .test {
      margin-left:10%;
  }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    padding-left: -3%;
/*  width: auto ! important;
    padding-right: 1% */;
}

.card-class {
    color: #0a41c5 !important ; 
}

.card-class .ant-card-meta-title {
    color: #0a41c5 ; 
    text-align: center;
}

.ant-card-meta-title{
    font-size: 13px !important;
}