.companySetupclass{
    background-color: white;
    border-radius: 12px;
    padding-bottom: 2%;
    
}
.companySetupTable tr td:nth-child(5) {
    float: left;
    line-height: 22px;
}
.ant-switch-checked {
    background-color: #0ec936;
}
 .companySetupTable td{
    width: 325px;
} 
.companySetupTable tr {
    display: inline-block
  }
.companySetupTable td.break {
    float: left;
    line-height: 22px;
  }
.companySetupclass .ant-table-thead {
    display: none;
}
.companySetupclass tr:nth-child(odd) {
    background-color: #f2f2f2
}
.companySetupclass tr:nth-child(even) {
    background-color: #ffff
}
.button-createtrip-ghost{
    width: 100%;
    background-color: #f2f2f2;
    color: #0A41C5;
    border-color: #0A41C5;
   /*  margin-top: 13%;
    padding-left: 2%;
    padding-right: 2%; */
    white-space: normal;
    overflow-wrap: break-word;
    width: 232px !important;
    height: 50px !important;
  }

  .ant-drawer-title {
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 14px;
    color: #0A41C5;
}
.button-createtrip-ghost1{
    width: auto;
    background-color: #f2f2f2;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 14px;
    color: #0A41C5;
    margin-top: 0%;
    margin-bottom: 1%;
    margin-left:23px;
    padding-left: 2%;
    padding-right: 2%;
    border:none !important
  }

  .ant-btn-primary-disabled:hover, button[disabled] {
    color: #555555 !important;
    background-color: #CDCDD3 !important;
    border-color:#CDCDD3 !important;
  }

  /* PopConfirm Custom Btn */

  .custom-Popover .custom-btn {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px #0000000b;
    height: 24px;
    padding: 0 7px;
    font-size: 12px;
    border-radius: 2px;
  }

  .custom-Popover .anticon {
    color: #faad14;
    font-size: 14px;
}

.custom-modal .ant-modal-content{
  text-align: center;
  border-radius: 20px;
  color: #2C2C2C;
}

.custom-modal .ant-modal-body {
  padding: 30px 0px;
}

.custom-modal  .ant-modal-content img {
  margin-bottom: 20px;
}

.custom-modal  .ant-modal-content p {
  margin: 0px;
}

.custom-modal .ant-modal-footer {
  text-align: center;
}

.custom-back-arrow img{
  width: 23px;
  height: 20px;
  position: relative;
  bottom: 6px;
}

/* @media (max-width: 350px){
  .bulkUpload-back-btn.custom-back-arrow img{
    left: -230px !important;
    top: 0px;
  }
} */

.custom-back-arrow:focus{
  outline: 2px dotted white !important;
  text-decoration: none;
  outline-offset: 2px;
  outline-style: dotted;
 }

  /* button-disabled{
    width: auto;
    background-color: #c2c0c0;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 14px;
    color: rgb(87, 88, 92);
    margin-top: 0%;
    margin-bottom: 1%;
    margin-left:23px;
    padding-left: 2%;
    padding-right: 2%;
    border:none !important
  } */
  .configure-class{

    font-size: "12px";
    padding-left: "3%"
  }


  .checkbox-style{
    margin: -5px 0 ;
    margin-top: 2px ; 
    width: 45% ;
    background-color: transparent
  }

  .switch-sentence{
    color:#0A41C5;; 
    font-size: 12px;
    margin: 2% 0 2% 10%;
  }
  
  .row-class{
    margin-bottom: 1%; 
    margin-left: 3%, ;
    margin-right: 3%
  }

  .element-value{
    margin-right: 3%;
     margin-left: 10%; 
     font-size: 16px
  }

  .ant-drawer-body {
    padding: 12px;
  }

  .configure-disabled{
    color: rgb(145, 145, 153) !important; 
  }
  .list-class{
    margin-top: '1%' ; 
    margin-left: '-1%' 
}

  .delegate-button-createtrip-ghost{
    background-color: #f2f2f2;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 16px;
    color: #0A41C5;
    margin-top: 0%;
    margin-bottom: 1%;
    border-color: #0A41C5;
    padding: 0 7%;
    width: 232px !important;
    height: 50px !important;
  }
  .button-createtrip{
    /* width: 95%;
    background-color: #112A70;
    color: #fff;
    border-color: #112A70;
    margin-top: 13%;
    margin-bottom: 10%;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%; */
    background-color: #0A41C5;
    border-color: #0A41C5;
    color: #fff;
    height: auto;
    white-space: normal;
    overflow-wrap: break-word;
    width: 232px !important;
    height: 50px !important;
  }
  .companySetupclass input{
    font-size:20px;
    color: #999999;
    font-weight: normal;
    margin-left: 4%;
    width: 80%;
    border: none;
    border-bottom: 1px solid lightgrey;
  }

.ant-table tr:hover td { 
    background-color: inherit !important;     
    opacity: 1.0;
 }
 .ant-table-tbody .input-text{
    font-size:20px;
    color: #636466;
    width: 100%;
    background: transparent;
    font-weight: normal;
    margin-left:0%;
    white-space: initial
}
.companySetupclass .input-text{
    font-size:20px;
    color: #636466;
    width: 100%;
    background: transparent;
    font-weight: normal;
    word-break: break-word;
}
.companyList{
    font-size:16px;
    padding-left: 7%;
}
.uploadPolicy{
    cursor: pointer;
    color: #0A41C5;
    text-decoration: underline;
}
.company-name{
    font-size: 26px;
    color: #112A70;
    margin-top: 2%;
    text-align: center
}
.addnewcompany{
    margin-bottom: 1%;
    margin-top: -1%;
}
.admin_heading{
    color: #112A70;
    padding-top: 2%;
   /*  margin-left: 2%; */
    font-size: 20px
}
div#panId.focused{
    border: 2px solid blue;
    
}
div#panId.panclass{
    border: none;
    border-bottom: 1px solid lightgrey;
    min-width: 15%;
}
[contentEditable=true]:empty:not(:focus):before{
    content:attr(data-text);
    font-size: 16px;
    color: #999999;
    font-weight: normal;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
}
.virtualCursorClass{
    animation: blink-empty 1s infinite;
}
.companyNameList{
    color: #1F337C;
    font-size: 20px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
}
.compDetailsRow{
    background-color: #F8F8F8;
    margin-bottom: 2%;
    margin-right: 1%;
    margin-left:1%;
}
.compFunctionalRow{
    background-color: #F8F8F8;
}
.listAttributes{
    font-size: 14px;
    color: #636466;
}
.ant-drawer-content{
  overflow: hidden !important;
}
/*********card settings css*********/

  
@media all and (max-width:700px){
    .company-name{
        font-size:18px;
        color: #112A70;
        margin-top: 2%;
        text-align: center
    }
    .admin_heading{
        color: #112A70;
        padding-top: 2%;
        margin-left: 2%;
        font-size: unset
    }
    .button-createtrip-ghost{
        width: 100% !important;
        height: 50px !important;
      }
      .button-createtrip{
        width: 100% !important;
        height: 50px !important;
      }
      .delegate-button-createtrip-ghost{
        width: 100% !important;
        height: 50px !important;
      }
}

@media all and (max-width:767px){
    .button-createtrip-ghost{
        width: 100% !important;
        height: 50px !important;
      }
      .button-createtrip{
        width: 100% !important;
        height: 50px !important;
      }
      .delegate-button-createtrip-ghost{
        width: 100% !important;
        height: 50px !important;
      }
    }
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header{
    padding-left: 12px;
    background-color: white; 
}


@media (max-width: 1190px){
  .super-admin.company-name-row{
     margin-left: 13% !important;
  }
  .company-name-row{
    margin-left: 25% !important;
 }
  .company-name-row-elan{
    margin-left: 210% !important;
  }

  .super-admin.company-name-row-elan {
    margin-left: 40% !important;
  }
}

.pseudoButtonIcon[disabled], .pseudoButton[disabled]{
  background-color: transparent !important;
}
.addNewCompanyButton span {
  padding: 0px !important;
}

button.ant-btn.addNewCompanyButton.ant-btn-primary.ant-btn-background-ghost {
  font-size: 1em;
  font-weight: 400;
}

button.ant-btn.addNewCompanyButton.ant-btn-primary.ant-btn-background-ghost:hover:not([disabled]) {
 background-color:#0A41C5 !important;
 color:#fff;
}
