
.expenseCategoryclass{
    background-color: white;
    border-radius: 12px;
}
.admin_heading{
    color: #112A70;
    padding-top: 2%;
   /*  margin-left: 4%; */
    font-size: 20px;
}
.newcat{
    margin-bottom: 0%;
    padding-top: 0px;
}
.widthselect{
    width: 100%;
}
.leftmargin{
    margin-left: 10%;
}
.rowspaceexp{
    margin-top: 4%;
}
.centerText{
    align-items: center;
    justify-content: space-around;
    display: flex;
    float: none;
}
 .rowspaceexp input {
    font-size: 16px;
    color: #999999;
    font-weight: normal;
    margin-left: 2%;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
}
.columnrenderclass{
    width: 100%;
    border: none;
    /* border-bottom: 1px solid lightgrey; */
    background: transparent;
    text-align: center;
}
.editabletextbox{
    margin: -5px 0;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #666666;
    background: transparent;
}
.errorInput{
    border: 1px solid red !important;
}
.margibnLeftExpCat{
    margin-left: 2%;
    text-align: left;
}
.expenseAddButton .anticon-plus-circle {
    font-size: 30px;
    color: #0A41C5;
}
.button-createtrip-ghost{
    width: auto !important;
    background-color: #f2f2f2;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 16px;
    color: #0A41C5;
    margin-top: 0%;
    margin-bottom: 1%;
    border-color: #0A41C5;
    padding: 0 7%;
}
.width-class {
    width: 232px !important;
}
.button-align{
    text-align: right;
}
.table-modal .ant-modal-content {
    width: 190%;
    margin-left: -45%;
}

.addBulkExp td span{
    padding-left: 4px !important;
}

@media all and (max-width: 992px) {
    .button-createtrip-ghost{
      margin: 1% 0;
      width: 80% !important;
    }
    .expenseAddButton .button-align{
        text-align: center;
    }
    .focusbutton {
        width: 50% !important;
        margin-right: 2% !important;
    }
    .width-class {
        width: 100% !important;
    }
}
@media all and (max-width:700px) {
    .table-modal .ant-modal-content {
        width: 98%;
        margin-left: 0%;
    }
    .focusbutton {
        width: 67%!important;
        margin-top: 6%!important;
    }
}
@media all and (max-width:400px) {
    .focusbutton {
        width: 33%!important;
        margin-top: 6%!important;
    }
    .h2, h2 {
        font-size: 22px;
    }
    .custom-ui{
        width: 225px !important;
    }
}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .table-modal .ant-modal-content {
        width: 150%;
        margin-left: -25%;
    }
}
@media all and (max-width:770px){
    .admin_heading{
        color: #112A70;
        padding-top: 2%;
        margin-left: 4%;
        font-size: unset;
    }
}
@media all and (max-width: 800px) {
    .margibnLeftExpCat{
        margin-left: 0%;
    }
    .widthselect{
        width: 100%;
    }
    .leftmargin{
        margin-left: 0%;
    }
}

.columnrenderclass  .ant-select .ant-select-focused .ant-select-selection,
.columnrenderclass .ant-select-selection:focus,
.columnrenderclass .ant-select-selection:hover,
.columnrenderclass .ant-select-selection:active {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px !important;
}
.columnrenderclass  .ant-select-selection{
    background: transparent;
    border: none;
    border-radius: 0px;
}
.selectClasses .ant-select .ant-select-focused .ant-select-selection,
.selectClasses .ant-select-selection:focus,
.selectClasses .ant-select-selection:hover,
.selectClasses .ant-select-selection:active {
  background: transparent;
 border-bottom: 1px solid lightgrey;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px !important;
}
.viewEditProfileCls .ant-table-thead > tr > th {
    padding: 16px 16px;
    word-break: break-word;
}
.viewEditProfileCls .ant-table-tbody > tr > td {
    padding: 16px 16px;
    word-break: break-word;
    max-width: 182px;
}
td.column{
    text-align: left !important;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis; 
}
.ViewEditProfileClass .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 10px;
    word-break: normal !important;
}
/* .tooptip {
    visibility: hidden;
  width: 120px;
  background-color: lightgrey;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  /* position: absolute;
  z-index: 1;
} */ 