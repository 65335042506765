
body {
  margin          : 0;
  padding         : 0;
  font-family     : U.S.BankCircularWeb-Regular;
  background-color: #f0f2f5;
}

div.rfELn,
div.botjNb {
  width        : 500px;
  margin       : 50px auto;
  margin-top   : 50px;
  margin-right : 112px;
  margin-bottom: 50px;
  margin-left  : auto;
  text-align   : center;
  box-shadow   : none;
  padding      : 20px;
}

form.ant-form.ant-form-horizontal.login-form,
form.ant-form.ant-form-horizontal.reset-password-form,
form.ant-form.ant-form-horizontal.forgot-password-form,
form.ant-form.ant-form-horizontal.mfa-password-form,
form.ant-form.ant-form-horizontal.sendotp-form,
form.ant-form.ant-form-horizontal.verifyotp-form {
  margin    : auto;
  margin-top: 20px;
}

.login-form .ant-input,
.reset-password-form .ant-input,
.forgot-password-form .ant-input,
.mfa-form .ant-input,
.sendotp-form .ant-input,
.verifyotp-form .ant-input {
  font-family: "U.S.BankCircularWeb-Regular";
  /* font-family       : "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "U.S.BankCircularWeb-Regular", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  -webkit-box-sizing: border-box;
  box-sizing        : border-box;
  margin            : -33px;
  padding           : 0;
  list-style        : none;
  position          : relative;
  padding           : 4px 11px;
  width             : 100%;
  height            : 32px;
  font-size         : 14px;
  line-height       : 1.5;
  color             : #333333;
  background-color  : #fff;
  background-image  : none;
  border-radius     : 4px;
  border-top        : 1px solid #737373;
  border-left       : 1px solid #737373;
  border-right      : 1px solid #737373;
  border-bottom     : 1px solid #737373;
  -webkit-transition: all .3s;
  -o-transition     : all .3s;
  transition        : all .3s;
}

@media (max-width: 768px){
  .verifyotp-form .ant-input{
    padding: 0px 0px !important;
  }
}

@media (max-width: 400px){
  .loginScreen{
    display: block;
  }
  .ant-input.loginclass, .ant-btn.login-button.login-block-button.ant-btn-primary, .forgetPassLink{
    left: 0px;
    top: 0px;
  }
  .cqelwF {
    width: 220px !important;
    margin: 17px 26vw !important;
    margin-top: 39vh !important;
    text-align: center;
    /* padding: 0px; */
}
.eScDkb {
  width: 141px!important;
  margin:29vh 44vw 45px !important;
  text-align: center;
  padding: 0px !important;
}
   .verifyotp-form .ant-input{
    padding: 0px 0px !important;
  }
  .ant-alert.ant-alert-no-icon{
    margin-top: 17px !important;
  }
}
.ant-input:hover, .ant-input {
  border-color      : #666666;
  border-right-width: 1px !important;
}


/* .login-page {
    background:url("../images/loginBG.png") no-repeat top center fixed;
    background-size: 100% 100%;
  -webkit-background-size: cover; 
  -moz-background-size: cover;    
  -o-background-size: cover;   
   background-position: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  } 
    /***************option1****************/
/* .login-page{
        background-image: url('../images/newBG.png'), url('../images/loginBG11.png');
        background-position: 20% 50%, left top;;
        background-repeat: no-repeat, repeat;
        background-size: 25%,cover;
        padding: 0px;
        
      } */
/******************my option**************/
/*  .login-page {
        background:url("../images/newBG.png")no-repeat top center fixed;
        background-size: 45%,100%;
        background-position: 20% 100%;
      } */

/****************option 4**************/
.login-page {
  /* background-image   : url('../images/Group 8009.svg'), url('../images/loginBG11.png'); */
  background-image   :  url('../images/loginBG11.png');
  background-position: 20%, left ;
  ;
  background-repeat: no-repeat, repeat;
  background-size  : 100%, cover;
  padding          : 0px;

}

/* .loginclass{
      margin-right: 2%;
      width:145%;
  } */
.loginclass {
  margin-right: 2%;
  width       : 145%;

}

.login-form .ant-input:focus,
.reset-password-form .ant-input:focus,
.forgot-password-form .ant-input:focus,
.mfa-form .ant-input:focus,
.sendotp-form .ant-input:focus,
.verifyotp-form .ant-input:focus, .sendotp-form .ant-select-selection:focus {
  border-right: 1px solid #737373 !important;
  outline :  dotted 2px black;
  text-decoration: none;    
  outline-offset: 2px;
  outline-style: dotted;
}

.login-button{
    background-color: #0A41C5;
    border-radius: 4px;
    color: white;
    border-color: #0A41C5;
    width: 135px;
    margin-left: -7%;
    padding: 0px
   
  }
.login-block-button{
    margin-left: 0%;
    width: 222px;
    height: 50px;
}
.show-password{
  position:absolute;
  left: 86%;
  top: -50%;
  z-index: 1000;
  font-size: 12px;
  color    : #19398A;
  cursor   : pointer;
}

.show-password-align{
  top:-20%
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #0A41C5;
  border-radius   : 4px;
  color           : white;
  border-color    : #0A41C5;
}

.ant-input {
  font-size: 18px !important;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .login-block-button {
    margin-top: 24px;
  }
}

div.rfELn .ant-select-selection-selected-value {
  font-family: 'U.S.BankCircularWeb-Regular' !important;
  color      : #636466;
  font-size  : 14px;
}

div.rfELn .ant-input:focus {
  border-right: 1px solid #737373 !important;
}

.login-form .ant-input::-moz-placeholder,
.reset-password-form .ant-input::-moz-placeholder,
.forgot-password-form .ant-input::-moz-placeholder,
.mfa-form .ant-input::-moz-placeholder,
.sendotp-form .ant-input::-moz-placeholder,
.verifyotp-form .ant-input::-moz-placeholder,
.ant-input::-moz-placeholder,
.calendar-input::-moz-placeholder, .text-area::-moz-placeholder {
	color: #666666 !important;
	opacity: 1
}

.login-form .ant-input:-ms-input-placeholder,
.reset-password-form .ant-input:-ms-input-placeholder,
.forgot-password-form .ant-input:-ms-input-placeholder,
.mfa-form .ant-input:-ms-input-placeholder,
.sendotp-form .ant-input:-ms-input-placeholder,
.verifyotp-form .ant-input:-ms-input-placeholder,
.ant-input:-ms-input-placeholder,
.calendar-input:-ms-input-placeholder, .text-area:-ms-input-placeholder {
	color: #666666 !important;
}

.login-form .ant-input::-webkit-input-placeholder,
.reset-password-form .ant-input::-webkit-input-placeholder,
.forgot-password-form .ant-input::-webkit-input-placeholder,
.mfa-form .ant-input::-webkit-input-placeholder,
.sendotp-form .ant-input::-webkit-input-placeholder,
.verifyotp-form .ant-input::-webkit-input-placeholder,
.ant-input::-webkit-input-placeholder,
.calendar-input::-webkit-input-placeholder, .text-area::-webkit-input-placeholder {
	color: #666666 !important;
}

.has-error .ant-form-explain{
  color: #C92323
}

@media all and (max-width: 1000px) {
  .loginclass {
    width: 227%;
  }

  .login-button {
    background-color: #0A41C5;
    border-radius   : 4px;
    color           : white;
    border-color    : #0A41C5;
    width           : 136px;
    margin-left     : -7%;

  }
}

/* @media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .userfont {
        font-weight:"lighter"; 
        font-size:70;
         color: "#ED1C24";
    }
}
@media only screen 
  and (min-width: 414px) 
  and (max-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .userfont {
        font-weight:"lighter"; 
        font-size:4ew;
         color: "#ED1C24";
    }
}
@media only screen 
  and (min-width: 1024px) 
  and (max-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .userfont {
        font-weight:"lighter"; 
        font-size:4ew;
         color: "#ED1C24";
    }
} */

.loginIconDiv {
  box-shadow   : 1px 1px 1px 1px grey;
  border-radius: 50%;
  width        : 113px;
  height       : 113px;

}

.userIcon_font {
  font-size: 4vw;
}

@media all and (max-width:768px) {
  .userIcon_font {
    font-size: 6vw;
  }

  /*************for option 1*************/
  .login-page {
    /* background-image   : url('../images/Group 8009.svg'), url('../images/loginBG11.png'); */
    background-image   : url('../images/loginBG11.png');
    background-position: 5% , left;
    ;
    background-repeat: no-repeat, repeat;
    background-size  : 100%, cover;
    padding          : 0px;

  }

  div.rfELn,
  div.botjNb {
    width        : 100%;
    margin       : 50px auto;
    margin-top   : 10%;
    margin-right : 112px;
    margin-bottom: 50px;
    margin-left  : auto;
    text-align   : center;
    box-shadow   : none;
    padding      : 20px;
  }
}

@media (width: 1024px) {
  .login-page {
    /* background-image   : url('../images/Group 8009.svg'), url('../images/loginBG11.png'); */
    background-image   : url('../images/loginBG11.png');
    background-position: 20% , left ;
    ;
    background-repeat: no-repeat, repeat;
    background-size  : 100%, cover;
    padding          : 0px;

  }

  div.rfELn,
  div.botjNb {
    width        : 500px;
    margin       : 50px auto;
    margin-top   : 50%;
    margin-right : 112px;
    margin-bottom: 50px;
    margin-left  : auto;
    text-align   : center;
    box-shadow   : none;
    padding      : 20px;
  }
}

.login-form .ant-form-item-label {
  display       : none;
  overflow      : hidden;
  line-height   : 39.9999px;
  white-space   : nowrap;
  text-align    : right;
  vertical-align: middle;
}

.button-wrapclass-tuto {
  white-space  : normal;
  overflow-wrap: break-word;
  width        : 185px !important;
  height       : 40px !important;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .button-wrapclass-tuto {
    white-space  : normal;
    overflow-wrap: break-word;
    width        : 190px !important;
    height       : 40px !important;
  }
}

.tuto-margintop {
  margin-top: 25%;
}

.tuto-middlemargin {
  margin-top: 5%;
}

@media all and (max-width:767px) {
  .button-wrapclass-tuto {
    margin-top   : 5%;
    white-space  : normal;
    overflow-wrap: break-word;
    min-height   : 32px !important;
    width        : 25% !important;
    height       : auto !important;
  }
}

@media all and (max-width:800px) {
  .tuto-margintop {
    margin-top: 2%;
  }
}

@media all and (width:1024px) {
  .tuto-margintop {
    margin-top: 12%;
  }
}

/* IC new login screens CSS */

.headingStyle-ic {
  font-family: 'U.S.BankCircularWeb-Bold';
  font-size  : 35px;
  font-weight: 600;
  color      : #19398A;
  text-transform: capitalize;
}

.loginText-ic {
  font-family: 'U.S.BankCircularWeb-Regular';
  color      : #191717;
  font-size  : 20px;
}

.loginMsgText-ic {
  font-family: 'U.S.BankCircularWeb-Regular';
  font-size  : 14px;
  color      : #666666;
}

.loginForm-ic {
  padding      : unset;
  border       : none;
  border-radius: 0px;
  border-bottom: 1px solid #666666;
}

.loginBtnClass-ic {
  width           : 160px;
  height          : 35px;
  border          : none;
  border-radius   : 0px;
  font-weight     : 500;
  background-color: #19398a;
  color           : white !important;
}

.loginBtnClass-ic:hover,
.loginBtnClass-ic:focus,
.loginBtnClass-ic:active
{
  background      : #19398A;
  font-weight     : 500;
  background-color: #19398a;
  color           : white !important;
}

.loginBtnClass-ic.ant-btn-primary-disabled:hover,
button[disabled] {
  background      : #f5f5f5;
  font-weight     : 500;
  color: #555555 !important;
  background-color: #CDCDD3 !important;
  border-color:#CDCDD3 !important;
}

.ant-btn.disabled, .ant-btn[disabled] {
  color: #555555 !important;
  background-color: #CDCDD3 !important;
  border-color:#CDCDD3 !important;
}
.loginBtnText-ic {
/* color         : white; */
  font-size     : 16px;
}

.rememberMe-ic{
  border-radius: 0px !important;
}

.rememberMeLabel-ic{
  font-size: 14px;
  font-weight: 700;
  color: #19398A;
  font-family: 'U.S.BankCircularWeb-Regular' !important;
  
}

.rememberMe-ic.Mui-focusVisible, .rememberMe-ic:focus, .ant-checkbox-input:focus + .ant-checkbox-inner ,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium:focus{
/* background-color: transparent !important; */
outline: 2px dotted black !important;
}

.link-ic:hover ,.link-ic:focus , .link-ic {
  color : #19398A;
  cursor: pointer;
}

.instant-card-ic {
  display       : inline;
  color         : #FFFFFF;
  font-size     : 14px;
  font-family   : "U.S.BankCircularWeb-Regular";
  letter-spacing: 1px;
}

.forgot-pass-email{
  margin-left: 1px !important;
}

.forgot-pass-send-otp-btn{
  margin-left: 30% !important;
}

.forgot-pass-send-otp-btn span{
  
  padding: 0 !important;
}

.otp-password, .reset-pass{
  margin: 0 !important;
}

.scrollable-container.ic-open-source{
  height: 350px;
}

.rememberMe-ic svg{
  font-size: 25px !important;
}


/* .scrollable-container.ic-open-source span{
  font-family: 'U.S.BankCircularWeb-Regular';
} */

@media (max-width: 4749px){
  .col-logo-ic{
    margin-top: 8% !important;
  }
}

@media (max-width: 3550px){
  .col-logo-ic{
    margin-top: 13% !important;
  }
}

@media (max-width: 1781px){
  .col-logo-ic{
    margin-top: 16% !important;
  }
}

@media (max-width: 1484px){
  .col-logo-ic{
    margin-top: 19% !important;
  }
}

@media (max-width: 1319px){
  .col-logo-ic{
    margin-top: 23% !important;
  }
}

.password-error, .username-error, .username-error-2,
.new-password-error, .new-pswd-strength-error, 
.cnf-password-error , .cnf-pswd-strength-error, .form-ele-error
{
  line-height: 2.8 !important;
}