.ViewEditProfileClass tr:nth-child(even) {
    background-color: white;
}
.ViewEditProfileClass tr:nth-child(odd) {
    background-color: white;
}
.ViewEditProfileClass{
    background-color: white;
    border-radius: 12px;
    margin-left: 0%;
    margin-right: 1%;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    padding-bottom: 1%;
    padding-top: 1%;

}
.ViewEditProfileClass .select1 {
    font-size: 16px;
    color: #999999;
    font-weight: normal;
    margin-left: -7%;
    width: 70%;
    margin-bottom: 2%;
    border: none;
    border-bottom: 1px solid grey;
}
.ViewEditProfileClass .col_center{
    display:flex;
    align-items: flex-start;
    justify-content: center;
}
.ViewEditProfileClass .avatar-class{
    background-color: grey;
    margin-top: 20%;
}
.margin-top-sider{
    /* margin-top: -8%; */
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 12px;
}
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden;
}
 
@media all and (max-width: 800px) {
    .margin-top-sider{
        /* margin-top: -3%; */
        font-family: 'U.S.BankCircularWeb-Regular' !important;
    }
    .ViewEditProfileClass .select1  {
        font-size: 16px;
        color: #999999;
        font-weight: normal;
        margin-left: 0%;
        width: 100%;
        margin-bottom: 2%;
        border: none;
        border-bottom: 1px solid grey;
    }
}
.ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}
.ViewEditProfileClass .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 16px 4px;
    word-break: break-word;
}
.ViewEditProfileClass span{
    font-size:14px;
}

.ViewEditProfileClass .ant-spin-dot {
    font-size: 20px;
}

.margin-top-sider .dashboardValue{
    color: #333333;
    font-weight: 600;
}

.admin_heading{
    color: #112A70;
    padding-top: 2%;
    /* margin-left: 2%; */
    font-size: 20px;
}
.avatar .ant-avatar {
    font-family: "U.S.BankCircularWeb-Regular";
    /* font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "U.S.BankCircularWeb-Regular", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-size: 32px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0px;
    padding: 10px;
    list-style: none;
    display: inline-block;
    text-align: center;
    background: #ccc;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: 64px;
    height: 64px;
    line-height: 32px;
    border-radius: 50%;
}
.ViewEditProfileClass .ant-input {
    font-family : "U.S.BankCircularWeb-Regular";
    /* font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "U.S.BankCircularWeb-Regular", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 1%;
    padding: 0;
    list-style: none;
    position: relative;
    margin-bottom: 2%;
    padding: 4px; 
    text-align: center;
   /*  padding-left: 80px; */
   margin-left: -21%;
    width: 100%;
    height: 32px;
    font-size: 15px;
    line-height: 1.5;
    color: black;
    font-weight: bold;
    background-color: #f2f2f2;
    background-image: none;
    border-radius: 7px;
    /* border-top: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey; */
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon{
    color: #CB4E01;
}

.btn-delete {
    font-size: 16px;
    margin-top: 13%;
    color: #ffffff;
    background-color: #DE162B;
    border-color: #DE162B;
    width: 170px
}

.margin-left-Name{
    text-align: center;
    margin-left: -39%;
    color: black;
    font-size: 16px;
}
.padding-left-class{
    padding-left: 7px;
    font-size: 14px;
}
.margin-left-RHS{
    margin-left: 0%;
    word-break: break-all;
}
.details-row{
    margin-left: 10%;
    padding-top: 2%;
    font-size: 14px;
    color: #333333;
}
 
.content-class{
    margin-left:0%;
}
@media all and (max-width: 767px) {
    .admin_heading{
        color: #112A70;
        padding-top: 2%;
        margin-left: 2%;
        font-size: unset;
    }
    .btnn .button-createtrip-ghost{
        width:90% !important;
        margin-top: 4%;
        margin-left: 5%;
        padding-left: 2%;
        padding-right: 2%;
      }
    .btnn .button-createtrip{
        width: 90% !important;
        margin-top: 4%;
        margin-left: 5%;
        text-align: center;
        padding-left: 2%;
        padding-right: 2%;
      }
    .ViewEditProfileClass .ant-input {
        margin-left: -12%;
    }
    .details-row{
        margin-left: 2%;
        padding-top: 2%;
    }  
    .margin-left-RHS{
        margin-left: 7%;
        white-space: nowrap;
    }
    .margin-left-Name{
        text-align: left;
        margin-left: 21%;
        color: black;
        font-size: 13px;
    }
    .avatar-details{
        margin-left: 5%;
     }
     .edit-icon-details{
         margin-left: 80%;
     }
}
/* @media all and (max-width: 700px) {
    .content-class{
        margin-left:15%
    }
    .margin-left-Name{
        text-align: left;
        margin-left: 4%;
        color: black;
        font-size: 13px;
    }
    .padding-left-class{
        padding-left: 0%;
    }
    .margin-left-RHS{
        margin-left: 0%;
    }
    .details-row{
        margin-left: 2%;
        padding-top: 2%;
    }
} */

#viewtable tr:nth-child(even) {
    background-color: #EDEDF0
}
#viewtable tr:nth-child(odd) {
    background-color: #EDEDF0
}

/************Delegate page css********************/
.delegatecontainer{
    background-color: white;
    border-radius: 12px;
}
.delegatecontainer .ant-input {
    font-family: "U.S.BankCircularWeb-Regular" !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-radius: 0px;
    padding: 4px 11px; 
    width: 100%;
    height: 35px;
    font-size: 14px;
    line-height: 1.5;
    color: #636466;
    background-image: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid lightgrey;
   
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
  }
  .datePicker .ant-input{
      font-size: 18px
  }
.delegatecontainer .ant-select .ant-select-focused .ant-select-selection,
.delegatecontainer  .ant-select-selection:focus,
.delegatecontainer  .ant-select-selection:hover,
.delegatecontainer .ant-select-selection:active {
  background: transparent;
  border-bottom: 1px solid lightgrey;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px !important;
}

.ant-table-placeholder {
    position: relative;
    padding-top: 180px;
    background: #fff;
    border-bottom:transparent;
    text-align: center;
    font-size: 14px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    color: #666666;
    z-index: 1;
}
@media all and (width:1024px){
    .padding-left-class{
        padding-left: 9px;
    }
}


.profilelist{
    color: #19398A;
    font-weight: 600;
    margin-left: 10px;
    font-size: 15px;
    margin-top: 5%;
}

.searchinput .ant-input{
    font-weight: normal !important;
    text-align: left !important;
}

.searchinput.profile{
    margin-top: 5px;
}

.searchinput .ant-input-search{
    margin-left: 10px;
    border: 1px solid #666666;
    width: 100%;
    border-radius: 4px;
    background-color: white;
}

.searchinput.profile .ant-input{
    border: 1px solid #DBD8D8;
    width: 100%;
    border-radius: 4px !important;
    background-color: white !important;
}

.checkbox-icon.anticon.anticon-border svg{
    fill: #19398A;
}

.checked-checkbox-icon svg path:nth-child(2){
    fill: #19398A;
}

.checked-checkbox-icon svg path:nth-child(3){
    fill: white;
}

.dropdown-btn{
    margin-left: -15%;
    padding: 0px 10px 0px 10px;
    font-size: 12px;
}

:root .dropdown-btn.ant-dropdown-trigger .anticon.anticon-down{
    font-size: 15px !important;
    color: #666666 !important;
    margin-left:5px
}

.ViewEditProfileClass .ant-layout-sider-children{
    background: #F2F2F5;
}

.editprofileDropdown{
    color: #333333;
}

.editprofileDropdown .ant-select-selection-selected-value {
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    color: #636466;
    font-size: unset;
    padding-right:0px;
}
.editprofileDropdown .ant-select-arrow{
    right:0px !important;
    top:15px;
}
.editprofileDropdown .ant-select-selection--multiple .ant-select-selection__choice__content {
    color: #333333;
    font-size: 12px;
}
.editprofileDropdown .ant-select-selection__placeholder{
    font-size: 12px;
}
.switchClass{
    color: #112A70;
    font-size: larger;
    margin-left: 7%;
}
.ant-switch-checked {
    background-color: #0A41C5;
}
.enableUser{
    font-weight: bold;
    font-size: larger;
    color: #0A41C5;
}
.search-box{
    
    background-color:  #636466!important;
    border-radius: 34px;
    width: 76%;
    /* height: 50px; */
    top: 5px;
    /* left: 5px; */
}
.search-box .ant-input{
  
    margin: 0!important;
    list-style: none!important;
    position: relative!important;
    border-radius: 34!important;
    width: 100%!important;
    height: 25px!important;
    font-size: 14px!important;
    line-height: 10px!important;
    color: #636466!important;
    background-color: #e8e8eb!important;
    
   
  
  
}

.details-editProfile{
    color: #333333;
    margin-left: 25%;
    font-weight: 600;
}

.details-editProfile .MuiSwitch-switchBase.Mui-checked.Mui-disabled{
    color: #328534;
}

.details-editProfile .MuiSwitch-switchBase.Mui-focusVisible{
    height: 20px;
    margin-top: 9px;
    outline: dotted 2px black;
}

.profile-toggle-button{
    width: 60px;
}

.details-editProfile .MuiSwitch-track,
.details-editProfile .MuiSwitch-switchBase.Mui-checked.Mui-disabled .MuiSwitch-track{
    color: #97C698 !important;
    opacity: 0.5 !important;
}

.MuiButtonBase-root.MuiAutocomplete-popupIndicator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    font-size: 32px;
    margin-bottom:15px;
}

.remove-filter-icon svg{
    fill: black;
}

.search-menuItem {
    width: 80%;
    margin-left: 15px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
}

.search-menuItem .MuiInputBase-input::placeholder{
    color: #666666 !important;
}

.superadmin.dropdown-btn{
    margin-left: 0px !important;
}

.comp-dropdown-btn .MuiButton-endIcon{
    margin-right: -10px;
}

.superadmin.dropdown-btn .MuiButton-endIcon{
    margin-right: -10px;
    margin-left: -10px !important;
}

.details-editProfile.phNo{
    margin-left: 12% !important;
}

.icon-Edit.disabled-icon, .disabled-icon{
    opacity: 0.6;
    cursor: not-allowed !important;
}

