/*********card settings css*********/
.cardsetrule{
    color: #999999;
    font-weight: normal;
    margin-top: 2%;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 16px;
}


.tableclass ant-table-thead > tr > th {
  background: none !important;
}

.tableheading{
  color: #636466 !important;
    font-weight: normal;
    margin-top: 2%;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 14px;
}
.cardsetrule1{
    color: #636466 !important;
    font-weight: normal;
    margin-top: 2%;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 16px;
}
.cardsettingclass{
    background-color: white;
    border-radius: 12px;
}
.cardsettingclass input{
    font-size:12px;
    color: #333333;
    font-weight: normal;
    width: 83px;
    padding: 5px 5px;
    border-radius: 5px;
    border: 1px solid grey;
  }
.cardsettingclass input[type=number]::-webkit-inner-spin-button, 
.cardsettingclass input[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
}
.admin_heading{
    color: #112A70;
    padding-top: 2%;
    margin-left: 2%; 
    font-size: 20px;
}
.button-createtrip-ghost{
    width: 100%;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
  font-size: 18px;
    background-color: #f2f2f2;
    color: #0A41C5;
    border-color: #0A41C5;
    margin-top: 13%;
    padding-left: 2%;
    padding-right: 2%;
  }
.button-createtrip{
    width: 100%;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    font-size: 18px;
    background-color: #0A41C5;
    color: #fff;
    border-color: #0A41C5;
    margin-top: 13%;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
  }
  .button-toppadding{
      margin-top: 5%;
  }
  .listAttributes {
    font-size: 14px;
    color: #636466;
}
.serviceInterfaceStyle {
  width: 83%!important;
  background: #fff!important;
  padding: 0 15px 29px !important;
  margin: 0 auto;
}
.label-external-sys-id, .external-sys-id-style-disabled::placeholder {
  color: #BCBABA !important;
}
.external-sys-id-style::placeholder{
  color: #8C8C8C !important;
}
.external-sys-id-style-disabled{
  width: 75% !important;
  border: 1px solid #BCBABA !important;
  border-radius: 5px !important;
  background-color: transparent !important;
}
  @media all and (max-width:770px){
    .admin_heading{
        color: #112A70;
        padding-top: 2%;
        margin-left: 2%;
        font-size: unset;
    }
    .button-toppadding{
        margin-top: 0%;
    }
    .cardsettingclass .button-createtrip-ghost{
        margin-top: 13%;
      }
    .cardsettingclass .button-createtrip{
        margin-top: 2%;
      }
}
.ant-select-auto-complete.ant-select .ant-input {
    border-width: 0;
}
.ant-select-selection{
    border: none;
    border-radius: 0px;
    border-bottom:  1.2px solid #d9d9d9;
}
.cardsettingclass .ant-select .ant-select-focused .ant-select-selection,
.cardsettingclass .ant-select-selection:focus,
.cardsettingclass .ant-select-selection:hover,
.cardsettingclass .ant-select-selection:active {
  background: transparent;
 border-bottom: 1px solid lightgrey;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px !important;
}

.classInput{
  border-right:none !important;
  border-left: none !important;
  border-top: none !important;
  border-radius: 0 !important;
  width: 15% !important;
}

.ant-radio-disabled+span, .ant-checkbox-disabled+span{
  color: #333 !important;
  opacity: 0.7 !important;
}