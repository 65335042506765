.highlight{

    /*   box-shadow: 0 0 1000px rgba(0, 0, 0, .65);
      box-shadow: 0 0 100vmax rgba(0, 0, 0, .65); */
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 1000;
  }
  .introjs-showElement, tr.introjs-showElement > td, tr.introjs-showElement > th {
    background-color: transparent !important
  }
  
  .introjs-helperLayer {
      box-sizing: content-box;
      position: absolute;
      z-index: 9999998;
      background-color: transparent !important;
      border: 1px solid #777;
      border: 1px solid rgba(0,0,0,.5);
      border-radius: 4px;
      box-shadow: 0 2px 15px rgba(0,0,0,.4);
      transition: all 0.3s ease-out;
  }
  .button-wrapclass-tutorial{
      background: transparent !important;
      color:white;
      border-color: white;
          height: auto;
      white-space: normal;
      overflow-wrap: break-word;
      /* min-height: 32px; */
      width: 200px !important;
      height: 40px !important;
  }
  .container {
      position: relative;
      width: 100%;
      max-width: 100%;
    }
    
    .container img {
      width: 100%;
      height: auto;
    }
    
    .nextbtn{
      position: absolute;
      top: 85%;
      left: 90%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: #555;
      color: white;
      border: 1px solid white;
      font-size: 16px;
      padding: 0px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
    }
    .skipbtn{
      position: absolute;
      top: 85%;
      left: 29%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      background-color: #555;
      color: white;
      border-color: white;
      font-size: 16px;
      padding: 0px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
    }
    
    .container .btn:hover {
      background-color: black;
    }

    .custom-ui {
      text-align: center;
      width: 545px;
      min-height: 200px;
      padding: 15px;
      background: #fff;
      box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
      color: rgb(0, 0, 0);
    }

    #react-confirm-alert {
          position: relative;
          z-index: 9999;
        }

    .btn-custom {
      margin-top: 5%;
      white-space: normal;
      width: 10vw;
      height: 50px;
      margin: 10px;
    }

    @media all and (max-width:1000px){
      .btn-custom{
        width: 20vw;
      }
    }