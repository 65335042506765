.container-header-elan {
  box-shadow                : 0px 3px 5px 5px #f2f2f2;
  background-color          : #214794;
  background-image          : url(../images/provisioner/header\ background.svg), linear-gradient(#1b3d8d, #549cd0);
  color                     : white;
  padding                   : 2% 3%;
  border-bottom-right-radius: 50px;
  height                    : 202px;
  background-size           : cover;
}

.box-content {
  top          : 50px;
  width        : 90%;
  /* height       : 600px; */
  background   : #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow   : 0px 0px 20px #0000000A;
  border-radius: 10px;
  opacity      : 1;
  margin       : auto;
}

.headingStyle {
  font-family: "U.S.BankCircularWeb-Light";
  
  font-size  : 35px;
  color      : #19398A;
  font-weight: 600;
  text-transform: capitalize;
}

.loginText {
  font-family: 'U.S.BankCircularWeb-Regular';
  color      : #191717;
  font-size  : 20px;
}

.loginMsgText {
  font-family: 'U.S.BankCircularWeb-Regular';
  font-size  : 14px;
  color      : #666666;
}

.loginForm {
  padding      : unset;
  border       : none;
  border-radius: 0px;
  border-bottom: 1px solid #666666;
}

.loginBtnClass {
  width           : 160px;
  height          : 35px;
  border          : none;
  border-radius   : 0px;
  font-weight     : 500;
  background-color: #19398a;
  color           : white !important;
}

.loginBtnClass:hover,
.loginBtnClass:focus,
.loginBtnClass:active
 {
  background      : #19398A;
  font-weight     : 500;
  background-color: #19398a;
  color           : white !important;
}

.loginBtnClass.ant-btn-primary-disabled:hover,
button[disabled] {
  background      : #f5f5f5;
  font-weight     : 500;
  color: #555555 !important;
  background-color: #CDCDD3 !important;
  border-color:#CDCDD3 !important;
}

.ant-btn[disabled].createCardBtnCss, .ant-btn[disabled]:hover.createCardBtnCss{
  color: #333333 !important;
  border:1px solid #666666 !important;
  background-color: #E4E4E6 !important;
}

.ant-btn.disabled, .ant-btn[disabled] {
  color: #555555 !important;
  background-color: #CDCDD3 !important;
  border-color:#CDCDD3 !important;
}
.loginBtnText {
  /* color         : white; */
  text-transform: uppercase;
  font-size     : 13px;
}

.scrollable-container {
  height    : 250px;
  overflow-y: scroll;
}

.ant-input {
  font-size: 18px;
}

.link:hover , .link {
  color : #19398A;
  cursor: pointer;
}

/* .create-holder-details-body span {
  color    : white;
  font-size: 10px;
} */

.elan-input-otp input {
  width        : 80%;
  border-top   : none;
  border-left  : none;
  border-right : none;
  border-radius: 0;
  height       : 27px;
}

.elan-resendBtn {
  border          : none;
  background-color: transparent;
}

.elan-resendBtn:disabled{
  border          : none;
  background-color: transparent !important;
  cursor: not-allowed;
}
/* .create-holder-details-body span {
  color    : white !important;
  font-size: 10px;
} */

.instant-card-elan{
  display: inline;
  color: white;
  font-size: 14px;
  font-family: 'U.S.BankCircularWeb-Light';
  position: relative;
  top: 3px; 
  letter-spacing: 3px;
  left:15px
}

.reset-password-text{
  font-size: 18px;
  color: #191717;
}

.elan-select .ant-select-selection:hover{
  background: transparent;
  border: none
}
.elan-select  .ant-select-selection {
  border: none;
  border-radius: 4px;
  /* border-bottom: solid #d9d9d9; */
}

.elan-select{
  height: 33px;
    width: 44px;
    border-bottom: none;
    border-radius: 4px;
    margin-left: 8%;
    background-color: white;
}

.scrollable-container.ic-open-source{
  height: 350px;
}

.rememberMe svg{
  font-size: 25px !important;
}

@media (max-width: 4749px){
  .col-logo-elan{
    margin-top: 7% !important;
  }
}

@media (max-width: 3550px){
  .col-logo-elan{
    margin-top: 12% !important;
  }
}

@media (max-width: 1718px){
  .col-logo-elan{
    margin-top: 17% !important;
  }
}

@media (max-width: 1484px){
  .col-logo-elan{
    margin-top: 19% !important;
  }
}

@media (max-width: 1319px){
  .col-logo-elan{
    margin-top: 23% !important;
  }
}

.password-error, .username-error, .username-error-2,
.password-error-2, .new-pswd-strength-error, 
.cnf-password-error , .confirm-pass-error, .form-ele-error
{
  line-height: 2.8 !important;
}

@media(max-width: 700px){
  .container-header-elan{
    height: 232px !important;
  }
}
