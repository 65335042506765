@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dn5y0c');
  src:  url('fonts/icomoon.eot?dn5y0c#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dn5y0c') format('truetype'),
    url('fonts/icomoon.woff?dn5y0c') format('woff'),
    url('fonts/icomoon.svg?dn5y0c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /*speak: none;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Save:before {
  content: "\e95f";
}
.icon-Edit:before {
  content: "\e960";
}
.icon-Delete-or-Disable:before {
  content: "\e961";
}
.icon-Cancel:before {
  content: "\e962";
}
.icon-Airplane-blue-size-1:before {
  content: "\e954";
}
.icon-Buildings-blue-size-1:before {
  content: "\e955";
}
.icon-Credit-Card-blue-size1:before {
  content: "\e956";
}
.icon-Delegation-white:before {
  content: "\e957";
}
.icon-Drop-down-blue:before {
  content: "\e958";
}
.icon-Home-white:before {
  content: "\e959";
}
.icon-Notify:before {
  content: "\e95a";
}
.icon-Right-arrow-white:before {
  content: "\e95b";
}
.icon-Sort-blue:before {
  content: "\e95c";
}
.icon-Symbol-38--3:before {
  content: "\e95d";
}
.icon-Trip-closure-blue:before {
  content: "\e95e";
}
.icon-Card-Settings:before {
  content: "\e903";
}
.icon-Create-Trip-Profile:before {
  content: "\e901";
}
.icon-Expence-Mapping:before {
  content: "\e905";
}
.icon-Recept-Rules:before {
  content: "\e906";
}
.icon-Reports:before {
  content: "\e907";
}
.icon-Company_Setup:before {
  content: "\e908";
}
.icon-View-and-edit-Profile:before {
  content: "\e909";
}
.icon-Close:before {
  content: "\e920";
}
.icon-Select1:before {
  content: "\e923";
}
.icon-Save1:before {
  content: "\e922";
}
.icon-Pdf:before {
  content: "\e924";
}
.icon-Edit1:before {
  content: "\e925";
}
.icon-Drop-down:before {
  content: "\e92b";
}
.icon-Add-new-out-of-pocket-expense---create-new-trip:before {
  content: "\e900";
}
.icon-Airplane-blue-size-11:before {
  content: "\e904";
  color: #0c2074;
}
.icon-Airplane-blue-size-2:before {
  content: "\e90a";
  color: #1b3281;
}
.icon-Airplane-blue-size-3:before {
  content: "\e90b";
  color: #1b3281;
}
.icon-Airplane-grey-size-1:before {
  content: "\e90c";
  color: #636466;
}
.icon-Airplane-grey-size-3:before {
  content: "\e90d";
  color: #636466;
}
.icon-Airplane-2:before {
  content: "\e90e";
  color: #1b3281;
}
.icon-attention:before {
  content: "\e90f";
  color: #c53d47;
}
.icon-Buildings-blue-size-11:before {
  content: "\e910";
  color: #1b3281;
}
.icon-Buildings-blue-size-2:before {
  content: "\e911";
  color: #1b3281;
}
.icon-Buildings-blue-size-3:before {
  content: "\e912";
  color: #1b3281;
}
.icon-Buildings-blue:before {
  content: "\e913";
  color: #1b3281;
}
.icon-Buildings-grey-size-1:before {
  content: "\e914";
  color: #636466;
}
.icon-Buildings-grey-size-3:before {
  content: "\e915";
  color: #636466;
}
.icon-button--blue-filled:before {
  content: "\e916";
  color: #0a41c5;
}
.icon-button--blue-outline:before {
  content: "\e917";
  color: #0a41c5;
}
.icon-button-grey-filled:before {
  content: "\e918";
  color: #737373;
}
.icon-Calendar:before {
  content: "\e919";
  color: #636466;
}
.icon-Checkbox---checked:before {
  content: "\e91a";
}
.icon-Checkbox---unchecked:before {
  content: "\e91c";
}
.icon-Collapse-chat:before {
  content: "\e91d";
  color: #fff;
}
.icon-Credit-Card-blue-size-3:before {
  content: "\e91e";
  color: #0c2074;
}
.icon-Credit-Card-grey-size1:before {
  content: "\e91f";
  color: #636466;
}
.icon-Credit-Card02:before {
  content: "\e921";
  color: #1b3281;
}
.icon-Currency-Exchange:before {
  content: "\e926";
  color: #1b3281;
}
.icon-Delegation:before {
  content: "\e927";
  color: #1b3281;
}
.icon-Delegation-Black:before {
  content: "\e902";
}
.icon-green-check:before {
  content: "\e928";
}
.icon-drop-down:before {
  content: "\e92a";
  color: #626366;
}
.icon-Email:before {
  content: "\e92c";
  color: #fff;
}
.icon-Expand-chat:before {
  content: "\e92d";
  color: #fff;
}
.icon-Expense-wizard:before {
  content: "\e92e";
  color: #0c2074;
}
.icon-Flight-itinerary:before {
  content: "\e92f";
  color: #636466;
}
.icon-green-status-1:before {
  content: "\e930";
  color: #00b818;
}
.icon-green-status:before {
  content: "\e931";
  color: #00b818;
}
.icon-Group--1:before {
  content: "\e932";
}
.icon-Group-7465:before {
  content: "\e933";
}
.icon-Group-7532:before {
  content: "\e934";
}
.icon-Group-7535:before {
  content: "\e935";
}
.icon-Group-7561:before {
  content: "\e936";
}
.icon-hide-password:before {
  content: "\e937";
}
.icon-show-password:before {
  content: "\e947";
}
.icon-Home:before {
  content: "\e938";
  color: #1b3281;
}
.icon-login-button:before {
  content: "\e939";
  color: #0a41c5;
}
.icon-Money-Dollar:before {
  content: "\e93a";
}
.icon-Notify1:before {
  content: "\e93b";
  color: #636466;
}
.icon-profile:before {
  content: "\e93c";
  color: #0c2074;
}
.icon-Radio-button---selected:before {
  content: "\e93d";
}
.icon-Radio-button---unselected:before {
  content: "\e940";
}
.icon-red-status:before {
  content: "\e942";
  color: #c53d47;
}
.icon-search .path1:before {
  content: "\e943";
  color: rgb(0, 0, 0);
}
.icon-search .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(180, 180, 180);
}
.icon-select-box:before {
  content: "\e945";
  color: #1f337c;
}
.icon-selected-button:before {
  content: "\e946";
  color: #0a41c5;
}
.icon-Sort:before {
  content: "\e948";
  color: #636466;
}
.icon-Trash-Garbage-Can:before {
  content: "\e949";
  color: #1b3281;
}
.icon-Trip-closure-blue1:before {
  content: "\e94a";
  color: #0c2074;
}
.icon-Trip-closure-grey:before {
  content: "\e94b";
  color: #636466;
}
.icon-Trip-closure-1:before {
  content: "\e94c";
  color: #636466;
}
.icon-Trip-closure:before {
  content: "\e94d";
  color: #0c2074;
}
.icon-Upload-attachment-02:before {
  content: "\e950";
  color: #0c2074;
}
.icon-Upload-attachment:before {
  content: "\e951";
  color: #0c2074;
}
.icon-US-BANK .path1:before {
  content: "\e91b";
  color: rgb(12, 32, 116);
}
.icon-US-BANK .path2:before {
  content: "\e929";
  margin-left: -3.8857421875em;
  color: rgb(12, 32, 116);
}
.icon-US-BANK .path3:before {
  content: "\e93e";
  margin-left: -3.8857421875em;
  color: rgb(12, 32, 116);
}
.icon-US-BANK .path4:before {
  content: "\e93f";
  margin-left: -3.8857421875em;
  color: rgb(12, 32, 116);
}
.icon-US-BANK .path5:before {
  content: "\e941";
  margin-left: -3.8857421875em;
  color: rgb(255, 255, 255);
}
.icon-US-BANK .path6:before {
  content: "\e94e";
  margin-left: -3.8857421875em;
  color: rgb(255, 255, 255);
}
.icon-US-BANK .path7:before {
  content: "\e94f";
  margin-left: -3.8857421875em;
  color: rgb(255, 255, 255);
}
.icon-US-BANK .path8:before {
  content: "\e953";
  margin-left: -3.8857421875em;
  color: rgb(222, 22, 43);
}
.icon-US-BANK1:before {
  content: "\e952";
}

.profile-Setting{
  background-image: url('../images/setting-svg.svg');
  display: inline-block;
}
