
.ant-table-thead > tr > th {
    text-align: center;
}
.upload-buttons{
    font-size: 15.44px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    margin-top: 9%;
    margin-left: 6%;
    width:35%;
}
.upload-buttons-save{
    font-size: 15.44px;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    margin-top: 9%;
    width:35%;
}
.upload-class{
    margin-top: 2%;
    border-radius: 15px;
    border-color:  #1F337C;
}
.ant-upload.ant-upload-drag {
    border: 1px dashed #1F337C;;
}
.tableicons{
    color: #0A41C5;
    font-size: 16px;
}

.upload-icon{
    font-size: 25px;
    color:#1F337C;
}
.useaction-Icon{
    margin-top: -7%;
    margin-left: -7%
}
.action-number{
    margin-top: -11%;
}
.action-filte-title{
    font-size: 26px;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    color:#0C2074;
    padding-left: 6%;
}
.action-filters{
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 1%;
}
.pending-actions{
    background-color: white;
    border-radius: 15px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    padding-left: 0.5%;
    padding-right: 0.5%;
    padding-top: 0.5%;
}
.actionfilterMarginTop{
  margin-top: -2%;
}
@media all and (min-width: 1900px) {
.action-number{
    margin-top: 0%;
}
.actionfilterMarginTop{
    margin-top: 2%;
  }
.useaction-Icon{
    margin-top: 0%;
    margin-left: 0%
}
.useractionNumber {
    font-size: 50px !important;;
}
.useractionTile {
    font-size: 18px !important;
}
}
@media all and (max-width: 993px) {
    .upload-buttons{
        font-family: 'U.S.BankCircularWeb-Regular' !important;
        margin-top: 2%;
        margin-left: 6%;
        width:35%;
    }
    .upload-buttons-save{
        font-family: 'U.S.BankCircularWeb-Regular' !important;
        margin-top: 2%;
        width:35%;
    }
    .useaction-Icon{
        margin-top: 2%;
        margin-left: 0%
    }
    .action-number{
        margin-top: 0%;
    }
    
    .upload-class{
        margin-top: 2%;
        border-radius: 15px;
        border-color:  #1F337C;
        min-height: 6vh;
        margin-bottom: 5%
    }
    
}
@media all and (max-width: 700px) {
.upload-class{
    margin-top: 2%;
    border-radius: 15px;
    border-color:  #1F337C;
    min-height: 6vh;
    margin-bottom: 5%
}
.upload-buttons{
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    margin-top: 2%;
    margin-left: 6%;
    width:35%;
}
.upload-buttons-save{
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    margin-top: 2%;
    width:35%;
}
}
@media all and (width: 1024px) {
.upload-class{
    margin-top: 2%;
    border-radius: 15px;
    border-color:  #1F337C;
}
}

.cardShadow{
    margin-bottom: 2%;
    border-radius: 15px;
    border: 2px solid #0A41C5;
  /*   box-shadow: 2px 0px 0px 0px #cccccc; */
}
.useractionTile{
    color: #0A41C5;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 12px;
    text-align: center;
}
.useractionNumber{
    color: #0A41C5;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 40px;
    text-align: center;
}
.tableHeader{
    font-weight: normal;
    color: #626467;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 14px;
}
.tableContent{
    font-weight: normal;
    color: #636466;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 16px;
}
.tableContentDelegation{
    font-weight: normal;
    color: #636466;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 16px;
}
 .ant-table-thead > tr > th .ant-table-filter-icon {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
} 
.marginRight{
    margin-right: 2%;
}

 @media all and (max-width:767px) {
    .ant-card-body{
        padding: 14px;
    }
    .action-filters{
        margin-top: 5%;
    }
.marginTop{
    margin-top: 2%;
}
.useractionNumber {
    color: #0A41C5;
    font-family: 'U.S.BankCircularWeb-Regular' !important;
    font-size: 20px;
    text-align: center;
}
.action-filte-title{
    font-size: 20px;
    font-family: 'U.S.BankCircularWeb-Bold' !important;
    color: #0C2074;
    padding-left: 6%;
}
} 
.pending-actions .ant-table-thead > tr > th .ant-table-column-sorter{
    color: #0A41C5;
}
 td.column-travelerName{
    text-align: left !important;
  }
  td.column-destination{
    text-align: left !important;
  }
  td.column-action{
    text-align: left !important;
  }
  td.column-tripStatus{
    text-align: left !important;
  }
  /****only for IE*************/
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .pending-actions .ant-table{
   min-width: 960px;
  }
}