/* .focusbutton:focus {
    outline: none;
    border:1px solid rgb(59, 153, 252) !important;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .cardFocus:focus{
    outline: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.15);
  }
  .focusicon:focus{
    outline: none;
    border:1px solid rgb(59, 153, 252);
  }
  .travelertypeSelector .ant-select-selection:focus{
    outline: none;
    border-bottom:1px solid rgb(59, 153, 252);
  }
  .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: lightgrey;
    border-bottom:1px solid rgb(59, 153, 252);
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px white;
            box-shadow: 0 0 0 2px white;
    border-right-width: 1px !important;
  }

  .checkbox input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}
  .ant-checkbox-input:focus + .ant-checkbox-inner{
  outline: rgb(59, 153, 252) auto 5px;
  }
  input[type='text']:focus {
    outline: none;
    border-bottom:1px solid rgb(59, 153, 252);
  }
  input[type='number']:focus {
    outline: none;
    border-bottom:1px solid rgb(59, 153, 252);
  }
  .phoneCode:focus{
    outline: none;
    border-bottom:1px solid rgb(59, 153, 252) !important;
  }
  .search-input > input:focus {
    outline: none;
    border-bottom: none;
  } */
 .menuitemFocus:focus{
  color: black;
  border-right: 4px solid #1F337C;
  background-color: #F7F7FA;
 }
/* 
 .menuitemFocus{
  border-right: 4px solid #F7F7FA;
 } */
