.receiptRulesclass{
    background-color: white;
    border-radius: 12px;
    
}
.receiptRulesclass .ant-table-thead {
    display: none;
}
.receiptRulesclass tr:nth-child(odd) {
    background-color: #f2f2f2
}
.receiptRulesclass tr:nth-child(even) {
    background-color: #ffff
}
.receiptRulesclass select {
    font-size: 16px;
    color: black;
    font-weight: normal;
    background: transparent;
    margin-left: 2%;
    width: 80%;
    border: none;
}
.pseudoButton{
    color: #0A41C5;
    border: none;
    background: transparent;
}
.pseudoButtonIcon{
    border: none;
    background: transparent;
}
.TypeSelect select{
    font-size: 16px;
    color: #999999;
    font-weight: normal;
    background: transparent;
    margin-left: 2%;
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgrey;
}
.admin_heading{
    color: #112A70;
    padding-top: 2%;
    /* margin-left: 4%; */
    font-size: 20px;
}
.newReceiptRule{
    margin-bottom: 1%;
    margin-top: -1%;
}
.radibuttonTop{
    margin-top: 4%;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #0A41C5;
}
.dividerclass{
    background-color: #112A70;
    width: 9px;
    height: 60px;
}
.aligntextClass{
    text-align: left !important;
}
.disableclass{
    color: #636466;
    font-style: italic;
    font-size: 16px;
    margin-left: 14%;
    font-weight: bold;
}
@media all and (max-width: 770px) {
    .aligntextClass{
        text-align: right !important;
    }
.admin_heading{
    color: #112A70;
    padding-top: 2%;
    margin-left: 4%;
    font-size: unset;
}
}
@media all and (max-width: 500px) {
    .aligntextClass{
        text-align: right !important;
    }
    .dividerclass{
        background-color: #112A70;
        width: 9px;
    } 

    .disableclass{
        margin-left: 0%;
        font-weight: bold;
    }
}
.receiptruleclass .ant-select .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:hover,
.ant-select-selection:active {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-right-width: 1px !important;
}
.receiptruleclass .ant-select-selection{
    background: transparent;
    border: none;
    border-radius: 0px;
}
.hrStyleclass{
    color: #8e919e;
    width: 97%;
    margin-top: 0.5%;
    border: .5px solid #8e919e;
    margin-left: 0%;
}