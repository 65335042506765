.ant-row.search{
  box-shadow: 0 5px 5px #ccc;
  z-index: 10;
}

.transaction-search-input .ant-input{
  font-family: 'Segoe UI';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 11px;
    list-style: none;
    position: relative;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 100%;
    height: 25px;
    font-size: 14px;
    line-height: 1.5;
    color: #333333;
    background-color: #f2f2f2;
    padding: 4px 35px;
    height: 34px;
}

button.ant-btn.transaction-search-button{
  background-color: #112A70;
  color: white;
  margin-left: 10px;
}

button.ant-btn.send-email-button{
  border-color: #112A70;
  color: #112A70;
}