
/* body {
  font-family: 'U.S.BankCircularWeb-Regular';
} */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 242px;
  height: 27PX;;
  padding: 10px 20px;
  /*font-family: Helvetica, sans-serif;
   font-weight: 300;
  font-size: 16px; */
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid lightgrey;
  background-color: #fff;
  font-family: "U.S.BankCircularWeb-Regular", sans-serif;
  font-weight: 300;
  font-size: 16px;
   border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; 
  max-height: 100px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
/* html input[disabled] {
  cursor: default;
  background: #bccacb26 !important;
  font-family: 'U.S.BankCircularWeb-Regular' !important;
} */

.ant-select-disabled .ant-select-selection {
  cursor: default;
  background: #bccacb26 !important;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
