.traveler-details {
  font-family: 'U.S.BankCircularWeb-Bold' !important;
}

.traveler-details .body{
  background-color: white
}

.traveler-details .body .ant-row:not(:last-child){
  border-bottom: 1px solid #e4e0e0;
}

.ant-row.traveler-identity, .ant-row.card-information{
  padding: 10px;
  word-break: break-all;
  font-size: 13px;
  display: flex;
  align-items: stretch;
}

.ant-row.manager-information{
  padding: 10px 10px 10px 15px;
  word-break: break-all;
  font-size: 13px;
}

.ant-row.traveler-identity > div, .ant-row.card-information > div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-row.traveler-identity .label, .ant-row.card-information .label, .ant-row.manager-information .label{
  font-style: italic;
  font-weight: normal;
  color: black;
  padding: 0px;
  font-size: 12px;
}

.ant-row.traveler-identity .traveler-name{
  font-size: 18px;
  font-weight: bold;
}

.ant-row.traveler-identity .traveler-emailid{
  word-break: break-all
}

.ant-row.traveler-identity span.status{
  margin: 5px 10px;
}

.ant-row.traveler-identity span.status > i.travel-icon{
  color: black;
  font-size: 18px;
}

.ant-row.traveler-identity span.status > i.status-icon{
  margin-left: 3px;
}